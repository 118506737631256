/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import HeaderComponent from '~/components/HeaderComponent';
import MultiselectAutocomplete from '~/components/MultiselectAutocomplete';
import DatePicker from '~/components/DatePickerComponent';
import ListAndOrderParent from '~/components/ListAndOrderParent';
import ModalConfirmation from '~/components/ModalConfirmation';
import RadioGroup from '../components/RadioGroup';
import AddClassObjects from '../components/AddClassObjects';
import Forms from '../components/Forms';
import { YesOrNoRadio, TRACK_TYPES, CONTENT_TYPES } from '../mocks/mock';
import { CourseAPICMS, CourseLessonTemplateAPICMS } from '~/services/apiCallsCMS';
import { useFeatures } from '~/hooks-querys/feature';
import { useResidencyDegree } from '~/hooks-querys/residencydegree';
import history from '~/services/history';
import { saveCreatedCourseName } from '~/store/modules/courses/actions';
import { convertStringToBoolean } from '~/utils/util';
import {
  transformOptions,
  handleChangeFeatures,
  handleChangeTrackTypes,
  filterFeatures,
  confirmRemove,
  handleBack,
  RenderRadioGroup,
  handleChangeData,
  RenderInput,
} from '../utils/utils';

import * as S from './styles';

function NewCourse() {
  const dispatch = useDispatch();
  const [formsData, setFormsData] = useState({});
  const [residencyDegree, setResidencyDegree] = useState([]);
  const [features, setFeatures] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedTrackTypes, setSelectedTrackTypes] = useState([]);
  const [contentType, setContentType] = useState([]);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [idDelete, setIdDelete] = useState('');
  const [objectsId, setObjectsId] = useState(CONTENT_TYPES.length + 1);
  const [radioAllExams, setRadioAllExams] = useState('');
  const { data: featuresData, isLoading: loadingFeatures } = useFeatures({ no_page: 1 });
  const { data: residencyDegreeData, isLoading: loadingResidencyDegree } = useResidencyDegree({
    no_page: true,
    ordering: 'abbreviation',
  });

  const handleChangeAllExams = (event) => {
    setRadioAllExams(event.target.value);
  };

  const handleRemove = () => {
    setShowModalConfirmation(false);

    const updatedContentTypes = contentType.filter((content) => {
      return content.id !== idDelete;
    });

    setContentType(updatedContentTypes);
  };

  const handleAdd = (content_type) => {
    const newItemName = CONTENT_TYPES.filter((content) => {
      return content.content_type === content_type;
    });

    const newItem = {
      id: objectsId,
      name: newItemName[0].label,
      object_type: newItemName[0].object_type,
      buttons: [
        {
          hasBorder: false,
          text: 'remover',
          icon: 'delete',
          action: () => confirmRemove(objectsId, setShowModalConfirmation, setIdDelete),
        },
      ],
    };

    setObjectsId(objectsId + 1);
    setContentType((contentType) => [...contentType, newItem]);
  };

  const submitForm = async () => {
    if (!formsData.name) {
      return toast.error('O nome é obrigatório para a criação do curso.');
    }

    if (
      (formsData.certificate_minimum_percentage_required &&
        formsData.certificate_minimum_percentage_required <= 0) ||
      formsData.certificate_minimum_percentage_required > 100
    ) {
      return toast.error('A porcentagem deve ser entre 1 e 100');
    }

    const subjectDate = new Date(formsData.subject_start_availability);
    const lessonDate = new Date(formsData.lesson_start_availability);
    let dateData;
    let modifiedUrlCheckout;

    if (formsData.subject_start_availability) {
      dateData = {
        subject_start_availability: `${subjectDate.getFullYear()}-${
          subjectDate.getMonth() + 1
        }-${subjectDate.getDate()}`,
      };
    }

    if (formsData.lesson_start_availability) {
      dateData = {
        ...dateData,
        lesson_start_availability: `${lessonDate.getFullYear()}-${
          lessonDate.getMonth() + 1
        }-${lessonDate.getDate()}`,
      };
    }

    if (formsData.url_checkout) {
      const hasHttpOrHttps = /^https?:\/\//i.test(formsData.url_checkout);
      modifiedUrlCheckout = hasHttpOrHttps
        ? formsData.url_checkout
        : `https://${formsData.url_checkout}`;
    }

    const transformedFormsData = {
      ...formsData,
      certificate_minimum_percentage_required: parseInt(
        formsData.certificate_minimum_percentage_required,
      ),
      mentoring_access: convertStringToBoolean(formsData.mentoring_access),
      track_available: convertStringToBoolean(formsData.track_available),
      residency_tree: convertStringToBoolean(formsData.residency_tree),
      subject_available: convertStringToBoolean(formsData.subject_available),
      lesson_available: convertStringToBoolean(formsData.lesson_available),
      url_checkout: modifiedUrlCheckout || formsData.url_checkout,
      ...dateData,
    };

    const { data } = await CourseAPICMS.create(transformedFormsData);

    let requests = [];

    if (radioAllExams === 'true') {
      const r1 = residencyDegree.filter((item) => {
        return item.label.includes('R1');
      });
      const extra = {
        residency_degree: 'r1',
      };
      requests.push(
        CourseAPICMS.addExam(data.id, r1[0].value === formsData.residency_degree ? extra : ''),
      );
    }
    contentType.forEach((content, index) => {
      requests.push(
        CourseLessonTemplateAPICMS.create({
          course_id: data.id,
          order: index + 1,
          object_type: content.object_type,
        }),
      );
    });

    try {
      Promise.allSettled([requests]).then(() => {
        dispatch(saveCreatedCourseName(data.name));
        history.push('/courses');
      });
    } catch {
      toast.error('Houve um erro ao criar o curso. Revise os campos e tente novamente');
    }
  };

  useEffect(() => {
    return setContentType(
      CONTENT_TYPES.map((content, index) => {
        return {
          id: index + 1,
          name: content.label,
          object_type: content.object_type,
          buttons: [
            {
              hasBorder: false,
              text: 'Remover',
              icon: 'delete',
              action: () => confirmRemove(index + 1, setShowModalConfirmation, setIdDelete),
            },
          ],
        };
      }),
    );
  }, []);

  useEffect(() => {
    if (!featuresData || loadingFeatures) return;
    return setFeatures(featuresData.map(transformOptions));
  }, [featuresData]);

  useEffect(() => {
    if (!residencyDegreeData || loadingResidencyDegree) return;
    return setResidencyDegree(residencyDegreeData.map(transformOptions));
  }, [residencyDegreeData]);

  const RenderDatePicker = (prop) => {
    return (
      <DatePicker
        label="Início"
        dateFormat="dd/MM/yyyy"
        value={formsData[prop] || null}
        handleChange={(event) => handleChangeData(event, prop, setFormsData)}
        setFormsData={setFormsData}
      />
    );
  };

  const content = [
    [
      {
        title: '',
        subtitle: '',
        shortPadding: true,
        borderBottom: false,
        items: [
          {
            subtitle: 'Nome',
            items: [RenderInput('Nome do curso', 'name', true, false, formsData, setFormsData)],
          },
          {
            subtitle: 'Acesso ao planner',
            fullWidth: true,
            items: [RenderRadioGroup(YesOrNoRadio, 'mentoring_access', formsData, setFormsData)],
          },
          {
            subtitle: 'Porcentagem para certificado',
            items: [
              RenderInput(
                'Porcentagem',
                'certificate_minimum_percentage_required',
                false,
                true,
                formsData,
                setFormsData,
              ),
            ],
          },
          {
            subtitle: 'Features do curso',
            items: [
              <MultiselectAutocomplete
                selectedOptions={selectedFeatures}
                setSelectedOptions={(options) =>
                  handleChangeFeatures(options, setSelectedFeatures, setFormsData)
                }
                label={loadingFeatures ? 'Carregando...' : 'Features'}
                placeholder="Selecione"
                options={features}
                fetchSuggestions={filterFeatures}
                loading={loadingFeatures}
                disabled={loadingFeatures}
                multiSelectLimit={features.length}
              />,
            ],
          },
          {
            subtitle: 'Nível de Residência',
            fullWidth: true,
            items: [RenderRadioGroup(residencyDegree, 'residency_degree', formsData, setFormsData)],
          },
          {
            subtitle: 'Url checkout',
            items: [
              RenderInput('Url checkout', 'url_checkout', true, false, formsData, setFormsData),
            ],
          },
        ],
      },
    ],
    [
      {
        title: 'Trilhas',
        subtitle:
          'Configuração para tipos de trilha: Pessoal, Simulado, Exame (Prova), Capítulo (Trilha-Pré e Trilha-Pós), Revisão e Trilha Inteligente',
        shortPadding: false,
        borderBottom: true,
        items: [
          {
            subtitle: 'Disponibilizar trilhas',
            items: [
              RenderRadioGroup(YesOrNoRadio, 'track_available', formsData, setFormsData),
              <MultiselectAutocomplete
                selectedOptions={selectedTrackTypes}
                setSelectedOptions={(options) =>
                  handleChangeTrackTypes(options, setSelectedTrackTypes, setFormsData)
                }
                label={loadingFeatures ? 'Carregando...' : 'Tipos'}
                placeholder="Selecione"
                options={TRACK_TYPES}
                fetchSuggestions={filterFeatures}
                multiSelectLimit={TRACK_TYPES.length}
              />,
            ],
          },
          {
            subtitle: 'Disponibilizar todas as provas na íntegra',
            fullWidth: true,
            items: [
              <RadioGroup
                data={YesOrNoRadio}
                handleRadio={handleChangeAllExams}
                radioValue={radioAllExams}
              />,
            ],
          },
          {
            subtitle: 'Disponibilizar questões R3',
            fullWidth: true,
            items: [RenderRadioGroup(YesOrNoRadio, 'residency_tree', formsData, setFormsData)],
          },
        ],
      },
      {
        title: 'Apostilas',
        subtitle: '',
        borderBottom: true,
        items: [
          {
            subtitle: 'Disponibilizar apostilas',
            fullWidth: true,
            items: [RenderRadioGroup(YesOrNoRadio, 'subject_available', formsData, setFormsData)],
          },
          {
            subtitle: 'Início da disponibilidade',
            fullWidth: true,
            items: [RenderDatePicker('subject_start_availability')],
          },
        ],
      },
      {
        title: 'Aulas',
        subtitle: '',
        borderBottom: false,
        items: [
          {
            subtitle: 'Disponibilizar aulas',
            fullWidth: true,
            items: [RenderRadioGroup(YesOrNoRadio, 'lesson_available', formsData, setFormsData)],
          },
          {
            subtitle: 'Início da disponibilidade',
            fullWidth: true,
            items: [RenderDatePicker('lesson_start_availability')],
          },
        ],
      },
    ],
    [
      {
        title: '',
        subtitle: '',
        shortPadding: true,
        borderBottom: false,
        items: [
          {
            subtitle: '',
            fullWidth: true,
            items: [
              <ListAndOrderParent
                parentData={contentType}
                setParentData={setContentType}
                parentAllowDrag={true}
              />,
            ],
          },
          {
            subtitle: '',
            fullWidth: true,
            items: [<AddClassObjects buttonsData={CONTENT_TYPES} onAdd={handleAdd} />],
          },
        ],
      },
    ],
  ];

  return (
    <>
      <HeaderComponent title={'Cursos'} />
      <S.Wrap>
        <S.Content>
          <Forms content={content} />
        </S.Content>
      </S.Wrap>
      <S.Footer>
        <S.BackButton onClick={handleBack}>
          <S.StyledArrowLeft />
          Voltar
        </S.BackButton>
        <S.SaveButton onClick={submitForm}>
          Salvar <S.StyledArrowRight />
        </S.SaveButton>
      </S.Footer>
      {showModalConfirmation && (
        <ModalConfirmation
          title="Excluir objeto de aula"
          message="Você deseja excluir esse objeto de aula?"
          actionText="Excluir"
          showModal={showModalConfirmation}
          handleClose={() => setShowModalConfirmation(false)}
          handleAction={handleRemove}
        />
      )}
    </>
  );
}

export default NewCourse;
