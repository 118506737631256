import styled from 'styled-components';
const mobileWidth = 450;

const textProps = {
  margin: 0,
  fontWeight: 600,
  color: 'rgb(0, 32, 91)',
};

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 24px 0 0 6px;
`;

export const FormsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 4px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
`;

export const FormsContent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 56px;
  width: 100%;
  flex: 0 0 auto;
  gap: 24px;

  @media (max-width: ${mobileWidth}px) {
    padding: 12px 18px;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const FormsTitle = styled.h1`
  ${textProps}
  font-size: 24.8px;
  line-height: 30px;

  @media (max-width: ${mobileWidth}px) {
    font-size: 20.3px;
  }
`;

export const FormsSubtitle = styled.h3`
  ${textProps}

  font-size: 20.3px;
  font-weight: 600;
  line-height: 27px;

  @media (max-width: ${mobileWidth}px) {
    font-size: 16px;
  }
`;

export const selectValueContainerStyle = {
  fontFamily: 'Montserrat',
  fontSize: 16,
  fontWeight: 400,
  color: '#00205B',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

export const SubmitButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
