import React, { useEffect, useState } from 'react';
import Player from '@vimeo/player';
import { Button, Breadcrumb } from '@medway-npm/ds-medstar';
import { toast } from 'react-toastify';
import { BiPlusCircle } from 'react-icons/bi';
import { ReactComponent as PencilIcon } from '~/assets/pencil-icon.svg';
import { ReactComponent as TrashIcon } from '~/assets/trash-icon.svg';
import HighlightModal from './components/HighlightModal';
import Loader from '~/components/Loader';
import ModalConfirmation from '~/components/ModalConfirmation';
import { useParams } from 'react-router-dom';
import { useLesson } from '~/hooks-querys/lesson';
import { useLessonModuleById } from '~/hooks-querys/lessonModule';
import { useGetLessonHighlights } from '~/hooks-querys/lessonHighlights';
import { useGetCourses } from '~/hooks-querys/course';
import { transformOptions } from '../Courses/utils/utils';
import { EMPTY_MODAL } from './components/emptySate';
import { LessonHighlightsAPICMS, TagAPICMS } from '~/services/apiCallsCMS';
import history from '~/services/history';
import { secondsToHms, timeToSeconds } from '~/utils/util';
import * as S from './styles';

export default function VideoHighlights() {
  const { course_id, lesson_module_id, lesson_id } = useParams();
  const [hasHighlights, setHasHighlights] = useState(false);
  const [mainVideoInfo, setMainInfoVideo] = useState({ title: '', urlEmbed: '' });
  const [currentVideoTime, setCurrentVideoTime] = useState(0);
  const [fullVideoDuration, setFullVideoDuration] = useState(0);
  const [showCreateHighlightModal, setShowCreateHighlightModal] = useState(false);
  const [createInformation, setCreateInformations] = useState(EMPTY_MODAL);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [showDeleteHighlightModal, setShowDeleteHighlightModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState();
  const [loadingDeleteId, setLoadingDeleteId] = useState();
  const [refreshVideo, setRefreshVideo] = useState(1);
  const [editInformations, setEditInformations] = useState(EMPTY_MODAL);
  const [showEditHighlightModal, setShowEditHighlightModal] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [isFetchingTags, setIsFetchingTags] = useState(false);
  const [lessonHighlightsData, setLessonHighlightsData] = useState([]);
  const [tagsToFetch, setTagsToFetch] = useState([]);
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({ hasNext: true, currentPage: 1 });

  const { data: lesson } = useLesson(lesson_id, {
    config: {
      enabled: !!lesson_id,
    },
  });

  const { data: lessonModule } = useLessonModuleById(lesson_module_id, {
    config: {
      enabled: !!lesson_module_id,
    },
  });

  const { data: courses } = useGetCourses(course_id, {
    config: {
      enabled: !!course_id,
    },
  });

  const { data: lessonHighlights, isFetching: fetchingLessonHighlights } = useGetLessonHighlights(
    { id: lesson_id, page: paginationInfo.currentPage },
    {
      config: {
        enabled: !!lesson_id && !!paginationInfo.hasNext,
      },
    },
  );

  const saveVideoInformations = () => {
    const iframe = document.getElementById(`main-video-${lesson_id}`);
    const player = new Player(iframe);

    player.pause();
    player.getDuration().then((seconds) => {
      setFullVideoDuration(seconds);
    });
    player.getCurrentTime().then(function (seconds) {
      setCurrentVideoTime(seconds);
    });
  };

  const createHighlight = () => {
    saveVideoInformations();
    setShowCreateHighlightModal(true);
  };

  const editHighlight = (highlight) => {
    setEditInformations({
      id: highlight.id,
      hasNoTag: highlight.has_no_tag,
      name: highlight.title,
      seconds: secondsToHms(highlight.start, true),
      tag: highlight.tags,
    });

    saveVideoInformations();
    setShowEditHighlightModal(true);
  };

  const confirmDelete = (id) => {
    saveVideoInformations();
    setShowDeleteHighlightModal(true);
    setIdToDelete(id);
  };

  const handleCloseCreateHighlightModal = () => {
    setShowCreateHighlightModal(false);
    setCreateInformations(EMPTY_MODAL);
  };

  const handleCloseEditHighlightModal = () => {
    setShowEditHighlightModal(false);
    setEditInformations(EMPTY_MODAL);
  };

  const changeVideoTime = (time) => {
    const idVideo = lesson?.vimeo_id;

    setMainInfoVideo({
      ...mainVideoInfo,
      urlEmbed: `https://player.vimeo.com/video/${idVideo}#t=${secondsToHms(
        timeToSeconds(time),
        false,
      )}`,
    });
  };

  const handleSaveCreateHighlight = () => {
    setLoadingCreate(true);
    const tagToCreate =
      createInformation?.tag && createInformation?.tag?.value === -1
        ? []
        : [createInformation?.tag?.value];

    LessonHighlightsAPICMS.create({
      lesson: lesson_id,
      start: timeToSeconds(createInformation.seconds),
      title: createInformation.name,
      ...(createInformation?.tag && { tags: tagToCreate }),
      has_no_tag: createInformation.hasNoTag,
    })
      .then(() => toast.success('Destaque criado com sucesso!'))
      .catch(() => toast.error('Erro ao criar destaque. Tente novamente mais tarde.'))
      .finally(() => {
        setLoadingCreate(false);
        setShowCreateHighlightModal(false);
        setCreateInformations(EMPTY_MODAL);
        changeVideoTime(createInformation.seconds);
        setRefreshVideo(refreshVideo + 1);
        setPaginationInfo({ hasNext: true, currentPage: 1 });
      });
  };

  const handleSaveEditHighlight = () => {
    if (!editInformations.id) {
      return toast.error('Erro ao atualizar destaque. Tente novamente mais tarde.');
    }
    setLoadingEdit(true);

    const tagToEdit =
      editInformations?.tag?.value && editInformations?.tag?.value !== -1
        ? [editInformations?.tag?.value]
        : [];

    LessonHighlightsAPICMS.update(editInformations.id, {
      lesson: lesson_id,
      start: timeToSeconds(editInformations.seconds),
      title: editInformations.name,
      ...(editInformations?.tag && { tags: tagToEdit }),
      has_no_tag: editInformations.hasNoTag,
    })
      .then(() => toast.success('Destaque atualizado com sucesso!'))
      .catch(() => toast.error('Erro ao atualizar destaque. Tente novamente mais tarde.'))
      .finally(() => {
        setLoadingEdit(false);
        setShowEditHighlightModal(false);
        setEditInformations(EMPTY_MODAL);
        changeVideoTime(editInformations.seconds);
        setRefreshVideo(refreshVideo + 1);
        setPaginationInfo({ hasNext: true, currentPage: 1 });
      });
  };

  const handleDeleteHighlight = () => {
    setLoadingDeleteId(idToDelete);
    setShowDeleteHighlightModal(false);

    LessonHighlightsAPICMS.delete(idToDelete)
      .then(() => toast.success('Destaque excluído com sucesso!'))
      .catch(() => toast.error('Erro ao excluir destaque. Tente novamente mais tarde.'))
      .finally(() => {
        setLoadingDeleteId();
        changeVideoTime(secondsToHms(currentVideoTime, true));
        setRefreshVideo(refreshVideo + 1);
        setLessonHighlightsData(
          lessonHighlightsData.filter((highlightData) => highlightData.id !== idToDelete),
        );
        setPaginationInfo({ hasNext: true, currentPage: 1 });
      });
  };

  const goToUrl = (url) => {
    history.push(url);
  };

  const getTagsData = async (tagsToFetch) => {
    let tagsDataCopy = [];

    await Promise.all(
      tagsToFetch.map(async (tagInfo) => {
        await TagAPICMS.get(tagInfo.tagId).then((result) => {
          if (result && result?.data) {
            tagsDataCopy.push({ id: tagInfo.id, tagData: transformOptions(result.data) });
          }
        });
      }),
    );

    return tagsDataCopy;
  };

  useEffect(() => {
    if (!lesson) return;

    const idVideo = lesson?.vimeo_id;

    setMainInfoVideo({
      title: lesson?.name,
      urlEmbed: `https://player.vimeo.com/video/${idVideo}`,
    });
  }, [lesson]);

  useEffect(() => {
    if (!lessonHighlights || fetchingLessonHighlights) return;
    const currentPage = paginationInfo.currentPage;
    let tagsCopy = [];
    lessonHighlights.results.map(async (highlight) => {
      if (highlight?.tags && highlight?.tags?.length > 0) {
        tagsCopy.push({ id: highlight.id, tagId: highlight.tags[0] });
      }
    });

    setTagsToFetch(tagsCopy);
    setPaginationInfo({
      currentPage: currentPage + 1,
      hasNext: !!lessonHighlights.next,
    });

    if (currentPage === 1) {
      return setLessonHighlightsData(lessonHighlights.results);
    }

    const lessonHighlightsIds = new Set(lessonHighlights.results.map((highlight) => highlight.id));
    const noDuplicatesArray = [
      ...lessonHighlightsData.filter((highlightData) => !lessonHighlightsIds.has(highlightData.id)),
      ...lessonHighlights.results,
    ];

    return setLessonHighlightsData(noDuplicatesArray);
  }, [lessonHighlights]);

  useEffect(() => {
    return setHasHighlights(lessonHighlightsData.length > 0);
  }, [lessonHighlightsData]);

  useEffect(() => {
    if (tagsToFetch.length === 0) return;
    setIsFetchingTags(true);
    let newLessonHighlight = [];

    getTagsData(tagsToFetch).then((result) => {
      lessonHighlightsData.forEach((highlight) => {
        const itemWithTag = result.find((item) => item.id === highlight.id);
        newLessonHighlight.push({ ...highlight, tags: itemWithTag?.tagData || [] });
      });

      setLessonHighlightsData(newLessonHighlight);
      setIsFetchingTags(false);
    });
  }, [tagsToFetch]);

  useEffect(() => {
    if (!lessonModule && !courses) return;

    setBreadcrumbItems([
      {
        label: courses?.name || '',
        href: `/courses/${course_id}/modules`,
      },
      {
        label: lessonModule?.name || '',
        href: `/courses/${course_id}/lesson-module/${lesson_module_id}`,
      },
    ]);
  }, [lessonModule, courses]);

  return (
    <>
      <S.Header>
        <Breadcrumb items={breadcrumbItems} onClick={(url) => goToUrl(url)} />
      </S.Header>
      <S.Wrap>
        <S.ContentArea>
          <S.VideoContainer key={`video-key-${refreshVideo}`}>
            <iframe
              id={`main-video-${lesson_id}`}
              src={`${mainVideoInfo.urlEmbed}`}
              title={mainVideoInfo.title}
              width="100%"
              height="100%"
              allowFullScreen
            />
          </S.VideoContainer>
        </S.ContentArea>
        <S.ContentArea>
          <S.ListContainer isEmpty={!hasHighlights || fetchingLessonHighlights}>
            {fetchingLessonHighlights ? (
              <Loader />
            ) : (
              <>
                {hasHighlights ? (
                  <>
                    <S.ListTitle>Pontos em destaque</S.ListTitle>
                    {lessonHighlightsData?.map((highlight) => {
                      return (
                        <S.HighlightItem
                          key={highlight.id}
                          toDelete={loadingDeleteId === highlight.id}
                        >
                          <S.HighlightInformations>
                            <S.HighlightDetails>
                              <S.MockThumbnail bgImage={highlight.thumbnail_url} />
                              <S.HighlightTitle>{highlight.title}</S.HighlightTitle>
                            </S.HighlightDetails>
                            <S.ControlHighlightItems>
                              <S.TimestampHighlight>
                                {secondsToHms(highlight.start, true)}
                              </S.TimestampHighlight>
                              <Button
                                startIcon={<PencilIcon />}
                                variant="secondary"
                                onClick={() => editHighlight(highlight)}
                              ></Button>
                              <Button
                                startIcon={<TrashIcon />}
                                variant="secondary"
                                onClick={() => confirmDelete(highlight.id)}
                              ></Button>
                            </S.ControlHighlightItems>
                          </S.HighlightInformations>
                          {!Array.isArray(highlight?.tags) && highlight?.tags && (
                            <S.HighlightCfa>
                              <S.CfaTag>CFA</S.CfaTag>
                              <S.CfaText>{highlight?.tags?.label}</S.CfaText>
                            </S.HighlightCfa>
                          )}
                          {highlight?.has_no_tag && (
                            <S.HighlightCfa>
                              <S.CfaTag>CFA</S.CfaTag>
                              <S.CfaText>Pendente</S.CfaText>
                            </S.HighlightCfa>
                          )}
                        </S.HighlightItem>
                      );
                    })}
                    <S.ButtonContainer>
                      <Button
                        size="large"
                        startIcon={<BiPlusCircle />}
                        onClick={createHighlight}
                        disabled={!mainVideoInfo.urlEmbed}
                      >
                        Adicionar destaque
                      </Button>
                    </S.ButtonContainer>
                  </>
                ) : (
                  <>
                    <S.EmptyTextAndButton>
                      <S.EmptyIconAndText>
                        <S.StyledHighlightIcon />
                        <S.EmptyText>
                          Adicione pontos de destaque em pontos específicos de parte do vídeo
                        </S.EmptyText>
                      </S.EmptyIconAndText>
                      <Button
                        size="large"
                        startIcon={<BiPlusCircle />}
                        onClick={createHighlight}
                        disabled={!mainVideoInfo.urlEmbed}
                      >
                        Adicionar destaque
                      </Button>
                    </S.EmptyTextAndButton>
                  </>
                )}
              </>
            )}
          </S.ListContainer>
        </S.ContentArea>
      </S.Wrap>
      {showCreateHighlightModal && (
        <HighlightModal
          show={showCreateHighlightModal}
          close={handleCloseCreateHighlightModal}
          seconds={currentVideoTime}
          information={createInformation}
          setInformations={setCreateInformations}
          fullDuration={fullVideoDuration}
          handleSaveHighlight={handleSaveCreateHighlight}
          loading={loadingCreate}
          isEdit={false}
          parentTag={lessonModule?.focus}
        />
      )}
      {showEditHighlightModal && (
        <HighlightModal
          show={showEditHighlightModal}
          close={handleCloseEditHighlightModal}
          seconds={currentVideoTime}
          information={editInformations}
          setInformations={setEditInformations}
          fullDuration={fullVideoDuration}
          handleSaveHighlight={handleSaveEditHighlight}
          loading={loadingEdit}
          isEdit={true}
          loadingTag={isFetchingTags}
          parentTag={lessonModule?.focus}
        />
      )}
      {showDeleteHighlightModal && (
        <ModalConfirmation
          title="Excluir destaque"
          message="Eu confirmo que estou excluindo o ponto de destaque. Essa ação não poderá ser revertida."
          actionText={
            <>
              <S.StyledTrashIcon /> Excluir
            </>
          }
          showModal={showDeleteHighlightModal}
          handleClose={() => setShowDeleteHighlightModal(false)}
          handleAction={handleDeleteHighlight}
        />
      )}
    </>
  );
}
