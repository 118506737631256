import React, { useState, useEffect } from 'react';
import { Ring } from 'react-spinners-css';
import InputMUI from '~/components/InputMUI';
import SelectComponent from '~/components/SelectComponent';
import SelectWithPagination from '~/components/SelectWithPagination';
import MultiselectAutocomplete from '~/components/MultiselectAutocomplete';
import Loader from '~/components/Loader';
import { loadInstitutions } from '~/pages/FlashcardsDecks/utils';
import { IoCheckmarkDoneSharp } from 'react-icons/io5';
import Modal from '@material-ui/core/Modal';
import { InstitutionAPICMS } from '~/services/apiCallsCMS';
import { useSpecialitys } from '~/hooks-querys/speciality';
import { useFilterCourse } from '~/hooks-querys/filters';
import { useListSubjectTags, useListSubject } from '~/hooks-querys/subject';

import * as S from './styles';

function ApostilleModal({
  id,
  show,
  close,
  action,
  actionText,
  loading,
  data,
  setData,
  modalAction,
}) {
  const [specialitys, setSpecialitys] = useState([]);
  const [subjectTags, setSubjectTags] = useState([]);
  const [courses, setCourses] = useState([]);
  const [loadingAll, setLoadingAll] = useState(false);

  const { data: specialitysData, isLoading: loadingSpecialities } = useSpecialitys({
    no_page: true,
    ordering: 'name',
    main_speciality: true,
  });

  const { data: subjectTagsData, isLoading: loadingSubjectTags } = useListSubjectTags({
    no_page: true,
    order: 'name',
  });

  const { data: coursesData, isLoading: loadingCourses } = useFilterCourse(
    {
      ordering: 'name',
      no_page: 1,
    },
    {
      config: {
        enabled: courses.length === 0,
      },
    },
  );

  const { data: subjectData, isLoading: loadingSubjectData } = useListSubject(id, {
    config: {
      enabled: modalAction === 'edit',
    },
  });

  const transformOptions = (value) => {
    if (!value.id || !value.name) return;

    return {
      label: value.name,
      key: value.id,
      value: value.id,
      abbreviation: value?.abbreviation || '',
    };
  };

  const handleBlur = (propName, value, setFormsData) => {
    return setFormsData((formsData) => {
      return { ...formsData, [propName]: value };
    });
  };

  const handleData = (value, propName) => {
    return setData({ ...data, [propName]: value });
  };

  const filteredCourses = async (search) => {
    if (!search) {
      return courses;
    }
    return courses.filter((item) => search.includes(item.label));
  };

  useEffect(() => {
    if (!subjectData || specialitys.length === 0 || subjectTags.length === 0) return;

    setLoadingAll(true);
    let hasInstitution = false;

    const specialityOption = specialitys.filter(
      (speciality) => speciality.value === subjectData?.speciality,
    );
    const subjectOption = subjectTags.filter((tag) => tag.value === subjectData?.subject_tag);
    const coursesOption = subjectData?.courses?.map(transformOptions) || [];

    const loadedProps = {
      name: subjectData?.name || '',
      speciality: specialityOption?.[0] || '',
      subject_tag: subjectOption?.[0] || '',
      ...(coursesOption.length > 0 && { courses: coursesOption }),
    };

    if (subjectData?.institution) {
      hasInstitution = true;
      const loadInstitution = async (isntitutionId) => {
        const response = await InstitutionAPICMS.getById(isntitutionId);
        loadedProps.institution = transformOptions(response.data);
        setData({ ...data, ...loadedProps });
      };
      loadInstitution(subjectData?.institution);
    }

    setLoadingAll(false);

    if (!hasInstitution) {
      setData({ ...data, ...loadedProps });
    }
  }, [subjectData, specialitys, subjectTags]);

  useEffect(() => {
    if (!specialitysData) return;
    setSpecialitys(specialitysData.map(transformOptions));
  }, [specialitysData]);

  useEffect(() => {
    if (!subjectTagsData) return;
    setSubjectTags(subjectTagsData.map(transformOptions));
  }, [subjectTagsData]);

  useEffect(() => {
    if (!coursesData) return;
    setCourses(coursesData.map(transformOptions));
  }, [coursesData]);

  const selectValueStyle = {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const RenderContent = () => {
    return (
      <S.Content>
        <S.ItemContainer>
          <S.ItemTitle>Nome</S.ItemTitle>
          <InputMUI
            label="Nome da apostila"
            value={data.name}
            placeholder="Nome da apostila"
            id="name"
            handleBlur={handleBlur}
            size="small"
            setFormsData={setData}
            inputCustomStyles={{ height: '40px' }}
          />
        </S.ItemContainer>
        <S.ItemContainer>
          <S.ItemTitle>Instituição</S.ItemTitle>
          <SelectWithPagination
            name="institutions"
            height={40}
            value={data.institution}
            onChange={(event) => handleData(event, 'institution')}
            loadOptions={loadInstitutions}
            placeholder="Instituição"
            placeholderStyle={{
              display: 'flex',
              fontSize: '14px',
            }}
            valueContainerStyle={{
              whiteSpace: 'nowrap',
              flexWrap: 'nowrap',
            }}
            backgroundColor="#fff"
            singleValueStyle={selectValueStyle}
          />
        </S.ItemContainer>
        <S.ItemContainer>
          <S.ItemTitle>Especialidade</S.ItemTitle>
          <SelectComponent
            name="speciality"
            placeholder={loadingSpecialities ? 'Carregando...' : 'Especialidade'}
            value={data.speciality}
            onChange={(event) => handleData(event, 'speciality')}
            options={specialitys}
            width={'100%'}
            controlStyle={{ marginLeft: 0 }}
            singleValueStyle={selectValueStyle}
            placeholderStyle={selectValueStyle}
            valueContainerStyle={{ flexWrap: 'nowrap' }}
            backgroundColor="#fff"
          />
        </S.ItemContainer>
        <S.ItemContainer>
          <S.ItemTitle>Tags/Módulo de apostila</S.ItemTitle>{' '}
          <SelectComponent
            name="subject-tags"
            placeholder={loadingSubjectTags ? 'Carregando...' : 'Tags/Módulo de apostila'}
            value={data.subject_tag}
            onChange={(event) => handleData(event, 'subject_tag')}
            options={subjectTags}
            width={'100%'}
            controlStyle={{ marginLeft: 0 }}
            singleValueStyle={selectValueStyle}
            placeholderStyle={selectValueStyle}
            valueContainerStyle={{ flexWrap: 'nowrap' }}
            backgroundColor="#fff"
          />
        </S.ItemContainer>
        <S.ItemContainer>
          <S.ItemTitle>Cursos vinculados</S.ItemTitle>{' '}
          <MultiselectAutocomplete
            selectedOptions={data.courses}
            setSelectedOptions={(event) => handleData(event, 'courses')}
            label="Cursos"
            placeholder="Selecione"
            options={courses}
            fetchSuggestions={filteredCourses}
            loading={loadingCourses}
            disabled={loadingCourses}
          />
        </S.ItemContainer>
      </S.Content>
    );
  };
  return (
    <Modal open={show} onClose={close} style={{ overflowY: 'auto' }}>
      <S.ModalWrap>
        <S.StyledCloseIcon onClick={close} />
        <S.ModalHeader>
          <S.ModalTitle>Nova apostila</S.ModalTitle>
        </S.ModalHeader>
        <S.ModalContent> {loadingAll ? <Loader /> : RenderContent()}</S.ModalContent>
        <S.ModalFooter>
          <S.BackButton onClick={close}>
            <S.StyledArrowLeft />
            Voltar
          </S.BackButton>
          <S.FillButton onClick={action} disabled={loading}>
            {loading ? (
              <Ring size={16} color="#fff" />
            ) : (
              <>
                <IoCheckmarkDoneSharp size={16} color={'#fff'} /> {` ${actionText}`}
              </>
            )}
          </S.FillButton>
        </S.ModalFooter>
      </S.ModalWrap>
    </Modal>
  );
}

export default ApostilleModal;
