import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Button } from '@medway-npm/ds-medstar';
import GreenBorder from '~/components/GreenBorder';
import SelectWithPagination from '~/components/SelectWithPagination';
import ImageUploader from '~/components/ImageUploader';
import { SelectStyle } from '~/pages/_layouts/default/styles';
import { TrackAnswerStudentAPI } from '~/services/apiCallsCMS';
import { loadStudent } from './utils';
import * as S from './styles';

function AprovaForms() {
  const { id } = useParams();
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [loadingSendingData, setLoadingSendingData] = useState(false);

  const handleSubmit = () => {
    if (!selectedStudent) {
      return toast.error('Selecione um aluno!');
    }
    if (!imageFile) {
      return toast.error('Selecione uma imagem!');
    }

    const params = {
      track_id: id,
      student_id: selectedStudent.value,
      answer_file: imageFile,
    };

    setLoadingSendingData(true);
    TrackAnswerStudentAPI.post(params)
      .then(() => {
        toast.success('Dados enviados com sucesso!');
        setSelectedStudent(null);
        setImageFile(null);
      })
      .catch(() => {
        toast.error('Erro ao enviar dados, tente novamente mais tarde.');
      })
      .finally(() => {
        setLoadingSendingData(false);
      });
  };
  return (
    <S.Container>
      <S.FormsContainer>
        <GreenBorder />
        <S.FormsContent>
          <S.FormsTitle>Aprova</S.FormsTitle>
          <S.ItemContainer>
            <S.FormsSubtitle>Aluno</S.FormsSubtitle>
            <SelectWithPagination
              height={40}
              width={'100%'}
              name="students"
              value={selectedStudent}
              onChange={(student) => setSelectedStudent(student)}
              loadOptions={loadStudent}
              placeholder={'Alunos'}
              singleValueStyle={{ ...S.selectValueContainerStyle, width: '95%' }}
              valueContainerStyle={S.selectValueContainerStyle}
              optionsStyle={SelectStyle}
              controlStyle={{
                ...SelectStyle,
                width: '100%',
                borderRadius: 8,
                background: '#F1F2F2',
                color: '#747678',
              }}
              placeholderStyle={{ color: '#d2d2d2', paddingLeft: 2 }}
            />
          </S.ItemContainer>
          <S.ItemContainer>
            <S.FormsSubtitle>Imagem</S.FormsSubtitle>
            <ImageUploader imageFile={imageFile} setImageFile={setImageFile} />
          </S.ItemContainer>
          <S.SubmitButtonContainer>
            <Button
              style={{ ...(!loadingSendingData && { width: '100%' }), maxWidth: '300px' }}
              onClick={handleSubmit}
              size="large"
              loading={loadingSendingData}
            >
              Enviar dados
            </Button>
          </S.SubmitButtonContainer>
        </S.FormsContent>
      </S.FormsContainer>
    </S.Container>
  );
}

export default AprovaForms;
