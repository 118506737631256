/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RiListCheck } from 'react-icons/ri';
import { IoCheckmarkDoneSharp } from 'react-icons/io5';
import { IoChevronDownSharp } from 'react-icons/io5';
import { MdAttachMoney } from 'react-icons/md';
import { ReactComponent as ExchanegIcon } from '~/assets/exchange-icon.svg';

import { Button } from '@medway-npm/ds-medstar';
import TextAreaComponent from '~/components/TextAreaComponent';
import ModalConfirmation from '~/components/ModalConfirmation';
import DatePicker from '~/components/StyledDatePicker';
import Loader from '~/components/Loader';
import SearchInputMaterialUi from '~/components/SearchInputMaterialUi';
import HeaderComponent from '~/components/HeaderComponent';
import FilterChips from '~/components/FilterChips';
import ButtonTextWithPopper from '~/components/ButtonTextWithPopper';
import SelectComponentMaterialUi from '~/components/SelectComponentMaterialUi';

import { downloadPDF } from '~/utils/util';
import DashboardCards from './components/DashboardCards';
import FilterDropdown from './components/FilterDropdown';
import ListActivities from './components/ListActivities';
import OrderPayments from './components/OrderPayments';
import HeaderList from './components/ListHeader';
import OffCanvas from './components/Offcanvas';
import OffCanvasMessaging from './components/OffCanvasMessaging';
import {
  useGetFinancialTask,
  useGetPaymentOrder,
  useGetFinancialTaskByMonth,
  useGetFinancialTaskByYear,
} from '~/hooks-querys/financial';
import {
  useWindowSize,
  formatPrice,
  checkIfArrayContainsAnother,
  objectIsEmpty,
} from '~/utils/util';
import { modifyNumberToFormat } from './utils';
import { Mixpanel } from '~/services/analytics';
import history from '~/services/history';
import {
  ExportReport,
  TaskFinancialAPICMS,
  PaymentOrderAPICMS,
  TaskAPICMS,
} from '~/services/apiCallsCMS';
import {
  FILTERS_INITIAL_STATE,
  AGGREGATION,
  ORDERING,
  STATUS_OPTIONS,
  ACTIONS_LIST,
  QUALITY,
  MULTIPLIER,
  PARTNER_FORMS_LINK,
} from './variables';

import * as S from './styles';

function Financial() {
  const { location } = useHistory();
  const querys = location.search;
  const params = new URLSearchParams(querys);
  let hasStatus = params.get('status');

  if (hasStatus && !['c', 'a', 'r', 'b', 'f', 'd', 'e'].includes(hasStatus)) {
    hasStatus = null;
    history.push('/financial');
  }
  const user = useSelector((state) => state.auth.user);
  const managePage = user.permissions.includes('manage_activities');
  const changeStatusPartner = user.permissions.includes('change_activity');
  const selectedStatus = !!hasStatus ? hasStatus : managePage ? 'f' : 'a';
  let position = 0;
  const { height } = useWindowSize();
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [search, setSearch] = useState();
  const [largeScreen, setLargeScreen] = useState(height > 700);
  const [filterChips, setFilterChips] = useState([]);
  const [formsData, setFormsData] = useState(FILTERS_INITIAL_STATE);
  const [selectedAggregation, setSelectedAggregation] = useState('Mês');
  const [selectedOrdering, setSelectedOrdering] = useState(ORDERING[0]);
  const [selectedActivitiesIds, setSelectedActivitiesIds] = useState({});
  const [disablePanel, setDisablePanel] = useState(true);
  const [selectedActivitiesCount, setSelectedActivitiesCount] = useState(0);
  const [visible, setVisible] = useState(true);
  const [listParams, setListParams] = useState({ status: selectedStatus });
  const [listOrderParams, setListOrderParams] = useState({ status: selectedStatus });
  const [openOffcanvas, setOpenOffcanvas] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [openOffcanvasMessaging, setOpenOffcanvasMessaging] = useState(false);
  const [offcanvasId, setOffcanvasId] = useState();
  const [showDataModal, setShowDataModal] = useState(false);
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [confirmCancelOrder, setConfirmCancelOrder] = useState(false);
  const [orderIdToCancel, setOrderIdToCancel] = useState();
  const [confirmForms, setConfirmForms] = useState(false);
  const [orderIdToUpdate, setOrderIdToUpdate] = useState();
  const [loadingButton, setLoadingButton] = useState(false);
  const [showErrorOrderModal, setShowErrorOrderModal] = useState(false);
  const [orderErrorMessage, setOrderErrorMessage] = useState('');
  const [errorOrderDataId, setErrorOrderDataId] = useState(null);
  const [showFooter, setShowFooter] = useState(false);
  const [helperChecked, setHelperChecked] = useState([]);
  const [childrenChecked, setChildrenChecked] = useState([]);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedSecondAction, setSelectedSecondAction] = useState(null);
  const [selectedNewPrice, setSelectedNewPrice] = useState();
  const [showActionConfirmationModal, setShowActionConfirmationModal] = useState(false);
  const [activitiesTasks, setActivitiesTasks] = useState([]);
  const [waitingRequestFromActivity, setWaitingRequestFromActivity] = useState(false);
  const [childrenActivities, setChildrenActivities] = useState({});
  const [newParams, setNewParams] = useState(null);
  const [fetchedTasks, setFetchedTasks] = useState({});
  const [expandedSummary, setExpandedSummary] = useState('');
  const [actionModalInfo, setActionModalInfo] = useState({
    title: '',
    message: '',
    buttonText: '',
    icon: '',
  });
  const [cardsInfo, setCardsInfo] = useState({
    activities: 0,
    totalValue: '0',
    countValues: '0',
    numberTitle: '',
    totalTitle: '',
    paymentTitle: '',
  });
  const isEmpty = useRef(false);
  const isListActivities = () => {
    return ['c', 'a', 'r'].includes(listParams.status);
  };

  const isOrderPayment = () => {
    return ['b', 'f', 'd', 'e'].includes(listParams.status);
  };

  const {
    data: tasksData,
    isFetching: fetchingTasksData,
    refetch: refetchTasks,
  } = useGetFinancialTask(newParams, {
    config: {
      enabled: isListActivities() && !!newParams?.responsible && !!expandedSummary,
    },
  });

  const {
    data: tasksDataByMonth,
    isFetching: fetchingTasksDataByMonth,
    refetch: refetchTasksByMonth,
  } = useGetFinancialTaskByMonth(listParams, {
    config: {
      enabled: isListActivities() && selectedAggregation === 'Mês',
    },
  });

  const {
    data: tasksDataByYear,
    isFetching: fetchingTasksDataByYear,
    refetch: refetchTasksByYear,
  } = useGetFinancialTaskByYear(listParams, {
    config: {
      enabled: isListActivities() && selectedAggregation === 'Ano',
    },
  });

  const {
    data: orderPaymentData,
    isFetching: fetchingOrderPaymentData,
    refetch: refetchOrderPayments,
  } = useGetPaymentOrder(
    { ...listOrderParams, no_page: true },
    {
      config: {
        enabled: isOrderPayment(),
      },
    },
  );

  const populateDashboard = () => {
    let count = 0;
    let valueCount = 0;

    if (isListActivities()) {
      if (!activitiesTasks) return;
      activitiesTasks.map((task) => {
        return task.results?.map((activity) => {
          count = count + activity?.task_count;
          return (valueCount = valueCount + (activity?.values || 0));
        });
      });
    } else if (isOrderPayment()) {
      if (!orderPaymentData) return;
      orderPaymentData.map((order) => {
        count = count + 1;
        return order.tasks?.map((task) => {
          return (valueCount = valueCount + (task?.values_to_pay?.total_calculated || 0));
        });
      });
    }

    let valueCountString = modifyNumberToFormat(valueCount);

    if (managePage) {
      switch (listOrderParams.status) {
        case 'd':
          setCardsInfo({
            ...cardsInfo,
            numberTitle: 'Ordens pagas',
            totalTitle: 'Total pago',
            paymentTitle: 'Solicitar pagamento',
            buttonText: 'Solicitar',
            activities: count,
            totalValue: formatPrice(valueCountString, true),
          });
          break;
        case 'b':
        case 'f':
        case 'e':
          setCardsInfo({
            ...cardsInfo,
            numberTitle: 'Ordens à pagar',
            totalTitle: 'Total à pagar',
            paymentTitle: 'Pagar ordens',
            buttonText: 'Pagar',
            activities: count,
            totalValue: formatPrice(valueCountString, true),
          });
          break;
        case 'c':
        case 'a':
        case 'r':
          setCardsInfo({
            ...cardsInfo,
            numberTitle: 'Atividades à pagar',
            totalTitle: 'Total à pagar',
            paymentTitle: 'Solicitar pagamento',
            buttonText: 'Solicitar',
            activities: count,
            totalValue: formatPrice(valueCountString, true),
          });
          break;
        default:
          break;
      }
    } else {
      switch (listParams.status) {
        case 'd':
          setCardsInfo({
            ...cardsInfo,
            numberTitle: 'Ordens pagas',
            totalTitle: 'Total recebido',
            paymentTitle: 'Solicitar pagamento',
            buttonText: 'Solicitar',
            activities: count,
            totalValue: formatPrice(valueCountString, true),
          });
          break;
        case 'c':
        case 'a':
        case 'b':
        case 'r':
        case 'f':
        case 'e':
          setCardsInfo({
            ...cardsInfo,
            numberTitle: 'Atividades à receber',
            totalTitle: 'Total à receber',
            paymentTitle: 'Solicitar pagamento',
            buttonText: 'Solicitar',
            activities: count,
            totalValue: formatPrice(valueCountString, true),
          });
          break;
        default:
          break;
      }
    }
  };

  const cardsData = [
    {
      icon: (
        <S.RoundIcon>
          <RiListCheck size={16} color={'#3D5582'} />
        </S.RoundIcon>
      ),
      title: cardsInfo.numberTitle,
      number: cardsInfo.activities,
      isPrice: false,
      disabled: false,
    },
    {
      icon: (
        <S.RoundIcon>
          <IoCheckmarkDoneSharp size={16} color={'#3D5582'} />
        </S.RoundIcon>
      ),
      title: cardsInfo.totalTitle,
      number: cardsInfo.totalValue,
      isPrice: true,
      disabled: false,
    },
    {
      icon: (
        <S.RoundIcon>
          <MdAttachMoney size={16} color={'#3D5582'} />
        </S.RoundIcon>
      ),
      title: cardsInfo.paymentTitle,
      number: cardsInfo.countValues,
      isPrice: true,
      tooltipText: `${
        managePage ? '' : 'Deadline pra emissão das notas dia 17 e pagamento até dia 22'
      }`,
      buttonText: cardsInfo.buttonText,
      buttonAction: () => handleButton(),
      buttonIcon: <MdAttachMoney size={16} color={'#fff'} />,
      selectedNumber: selectedActivitiesCount,
      disabled: disablePanel,
      loadingButton: loadingButton,
    },
  ];

  const getTaskData = (id, month, year) => {
    if (fetchingTasksData) return;

    delete newParams?.ended_at__month;
    delete newParams?.ended_at__year;

    const params = {
      ...newParams,
      responsible: id,
      ...((listParams?.ended_at__month || month) && {
        ended_at__month: listParams.ended_at__month || month,
      }),
      ...((listParams?.ended_at__year || year) && {
        ended_at__year: listParams.ended_at__year || year,
      }),
      status: selectedStatus,
      no_page: 1,
      ordering: selectedOrdering.key,
    };

    setNewParams(params);
  };

  const changeStatusTo = (newStatus) => {
    history.push(`/financial?status=${newStatus}`);

    setListParams({
      ...listParams,
      status: newStatus,
    });

    return setListOrderParams({
      ...listOrderParams,
      status: newStatus,
    });
  };

  const handleButton = async () => {
    if (loadingButton) return;
    setLoadingButton(true);

    if (isListActivities()) {
      let arrayOfIds = [];
      Object.keys(selectedActivitiesIds).forEach(function (key) {
        selectedActivitiesIds[key].forEach((activityId) => arrayOfIds.push(activityId));
      });

      Mixpanel.track("Clicou 'Financeiro - Solicitar pagamento'", {
        Data: format(new Date(), 'dd/MM/yyyy'),
        'Quantidade selecionada': arrayOfIds?.length || 0,
        'Valor da solicitação': cardsInfo.countValues,
      });

      const data = {
        tasks: arrayOfIds,
      };

      try {
        await PaymentOrderAPICMS.createOrder(data);
        toast.success(
          'Solicitação feita com sucesso! Para acompanhar filtre por status de pagamento',
        );

        setSelectedActivitiesIds({});

        setLoadingButton(false);

        return changeStatusTo('b');
      } catch {
        toast.error(
          'Erro ao solicitar. Tente novamente mais tarde ou entre em contato com o time de parceiros',
        );
        setLoadingButton(false);
      }
    } else if (isOrderPayment()) {
      const arrayOfIds = Object.keys(selectedActivitiesIds);

      Mixpanel.track("Clicou 'Financeiro - Pagar'", {
        'ID Ordens': arrayOfIds,
        'Valor das ordems': cardsInfo.countValues,
      });
      setShowDataModal(true);
    }
  };

  const handleConfirmDate = () => {
    const arrayOfIds = Object.keys(selectedActivitiesIds);

    Mixpanel.track("Clicou 'Financeiro - Pagar - Confirmar'", {
      'ID Ordens': arrayOfIds,
      'Valor das ordems': cardsInfo.countValues,
      Data: format(new Date(paymentDate), 'dd-MM-yyyy'),
    });

    PaymentOrderAPICMS.updateTasksStatus({
      orders: arrayOfIds,
      status: 'd',
      payment_date: format(new Date(paymentDate), 'yyyy-MM-dd'),
    })
      .then(() => {
        toast.success('Pagamento feito com sucesso');
        setShowDataModal(false);
        refetchOrderPayments();
      })
      .catch(() => {
        toast.error('Ocorreu um erro no pagamento. Tente novamente mais tarde');
      })
      .finally(() => setLoadingButton(false));

    setSelectedActivitiesIds({});

    return changeStatusTo('d');
  };

  const handleDataModalClose = () => {
    const arrayOfIds = Object.keys(selectedActivitiesIds);

    Mixpanel.track("Clicou 'Financeiro - Pagar - Voltar'", {
      'ID Ordens': arrayOfIds,
      'Valor das ordems': cardsInfo.countValues,
    });

    setShowDataModal(false);
  };

  const handleSearch = () => {
    Mixpanel.track("Clicou 'Financeiro - Buscar por palavras'", {
      Pesquisar: search,
    });
    if (isListActivities()) {
      setNewParams({ ...newParams, search: search });
      return setListParams({ ...listParams, search: search });
    } else if (isOrderPayment()) {
      return setListOrderParams({ ...listOrderParams, search: search });
    }
  };

  const handleSearchKey = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const deleteChipFilter = (key, label) => {
    const newFilterChips = filterChips.filter((chip) => chip.label !== label);
    setFilterChips(newFilterChips);

    if (isListActivities()) {
      switch (key) {
        case 'selectedPartner':
          delete listParams?.responsible;
          delete newParams?.responsible;
          setListParams(listParams);
          setNewParams(newParams);
          setFormsData({
            ...formsData,
            responsible: FILTERS_INITIAL_STATE.responsible,
          });
          break;
        case 'selectedResidencyDegree':
          delete listParams?.residency_degree_id;
          delete newParams?.residency_degree_id;
          setListParams(listParams);
          setNewParams(newParams);
          setFormsData({
            ...formsData,
            residency_degree_id: FILTERS_INITIAL_STATE.selectedResidencyDegree.value,
          });
          break;
        case 'selectedMonth':
          delete listParams?.ended_at__month;
          delete newParams?.ended_at__month;
          setListParams(listParams);
          setNewParams(newParams);
          setFormsData({
            ...formsData,
            ended_at__month: FILTERS_INITIAL_STATE.selectedMonth.value,
          });
          break;
        case 'selectedYear':
          delete listParams?.ended_at__year;
          delete newParams?.ended_at__year;
          setListParams(listParams);
          setNewParams(newParams);
          setFormsData({
            ...formsData,
            ended_at__year: FILTERS_INITIAL_STATE.selectedMonth.value,
          });
          break;

        default:
          break;
      }
    } else if (isOrderPayment()) {
      switch (key) {
        case 'selectedPartner':
          setListOrderParams({
            ...listOrderParams,
            responsible: FILTERS_INITIAL_STATE.selectedPartner,
          });
          break;
        case 'selectedMonth':
          setListOrderParams({
            ...listOrderParams,
            ended_at__month: FILTERS_INITIAL_STATE.selectedMonth,
          });
          break;
        case 'selectedYear':
          setListOrderParams({
            ...listOrderParams,
            ended_at__year: FILTERS_INITIAL_STATE.selectedYear,
          });
          break;
        case 'selectedStatus':
          setListOrderParams({ ...listOrderParams, status: FILTERS_INITIAL_STATE.selectedStatus });
          break;
        default:
          break;
      }
    }

    return setFormsData({ ...formsData, [key]: FILTERS_INITIAL_STATE[key] });
  };

  const populateChips = (data) => {
    setFilterChips([]);

    Object.keys(data).forEach(function (key) {
      if (!data[key] || !data[key].value) return;
      const isStatus = STATUS_OPTIONS.find((option) => option.label === data[key].label);
      if (isStatus) return;
      return setFilterChips((filterChips) => [
        ...filterChips,
        { key: key, label: data[key].label },
      ]);
    });
  };

  const handleFilter = () => {
    history.push(`/financial?status=${formsData?.selectedStatus?.value}`);

    setCardsInfo({ activities: 0, totalValue: '0', countValues: '0' });
    setSelectedActivitiesCount(0);
    setSelectedActivitiesIds({});

    Mixpanel.track("Clicou 'Financeiro - Filtrar - Filtrar'", {
      ...(managePage && { Parceiro: formsData?.selectedPartner?.label || '' }),
      Mês: formsData?.selectedMonth?.label || '',
      Ano: formsData?.selectedYear?.label || '',
      Status: formsData?.selectedStatus?.label || '',
      'Nível de Residência': formsData?.selectedResidencyDegree?.label || '',
    });

    const newParams = {
      ...(formsData.selectedPartner &&
        formsData.selectedPartner.label && {
          responsible: formsData.selectedPartner.value,
        }),
      ...(formsData.selectedMonth.label && {
        ended_at__month: formsData.selectedMonth.value,
      }),
      ...(formsData.selectedYear.label && {
        ended_at__year: formsData.selectedYear.value,
      }),
      ...(formsData.selectedStatus.label && {
        status: formsData.selectedStatus.value,
      }),
      ...(formsData.selectedResidencyDegree.label && {
        residency_degree_id: formsData.selectedResidencyDegree.value,
      }),
    };
    populateChips(formsData);
    setListOrderParams({ ...listOrderParams, ...newParams });
    return setListParams({ ...listParams, ...newParams });
  };

  const handleClear = () => {
    Mixpanel.track("Clicou 'Financeiro - Filtrar - Limpar'");
    const newParams = {
      selectedStatus: STATUS_OPTIONS.find((option) => option.value === selectedStatus),
    };

    setFormsData({
      ...FILTERS_INITIAL_STATE,
      ...newParams,
    });

    setFilterChips([]);

    setListOrderParams({ ...FILTERS_INITIAL_STATE, status: selectedStatus });
    return setListParams({
      status: selectedStatus,
    });
  };

  const handleAggregation = (item) => {
    Mixpanel.track("Clicou 'Financeiro - Agrupar por'", {
      'Agrupamento por': item.label,
    });
    setSelectedActivitiesIds({});
    setListParams({ ...listParams, aggregate: item.key });
    return setSelectedAggregation(item.label);
  };

  const handleOrdenation = (item) => {
    setNewParams({ ...newParams, ordering: item.key });
    return setSelectedOrdering({ label: item.label, key: item.key });
  };

  const handleOpenChat = (activityId, activityName) => {
    Mixpanel.track("Clicou 'Financeiro - Abrir Chat'", {
      'Nome da atividade': activityName,
    });
    setOffcanvasId(activityId);
    setOpenOffcanvasMessaging(true);
  };

  const handleOpenOffcanvas = (activityId, activityName) => {
    Mixpanel.track("Clicou 'Financeiro - Abrir Off-Canvas'", {
      'Nome da atividade': activityName,
    });
    setOffcanvasId(activityId);
    setOpenOffcanvas(true);
  };

  const handleChangeStatus = async (status, activityId) => {
    const data = { status: status };

    await TaskFinancialAPICMS.update(activityId, data);
    if (selectedAggregation === 'Mês') {
      await refetchTasksByMonth();
    } else if (selectedAggregation === 'Ano') {
      await refetchTasksByYear();
    }
    return await refetchTasks();
  };

  const handleChangeMultiplier = (item, activityId) => {
    TaskFinancialAPICMS.update(activityId, {
      multiplier: item.value,
    }).then(() => refetchTasks());
  };

  const handleChangeQuality = async (item, activityId) => {
    await TaskFinancialAPICMS.update(activityId, {
      quality: item.value,
    });
    if (selectedAggregation === 'Mês') {
      await refetchTasksByMonth();
    } else if (selectedAggregation === 'Ano') {
      await refetchTasksByYear();
    }
    return await refetchTasks();
  };

  const handleCloseOffCanvasMessaging = () => {
    setOpenOffcanvasMessaging(false);
  };

  const handleForms = (id, orderValue, date, type) => {
    switch (type) {
      case 'sendForms':
        Mixpanel.track("Clicou 'Financeiro - Preencher Forms - NF'", {
          'ID Ordem': id,
          'Data da ordem': date,
          Data: format(new Date(), 'dd/MM/yyyy'),
          'Valor da ordem': orderValue,
        });
        break;
      case 'resendForms':
        Mixpanel.track("Clicou 'Financeiro - Reenviar Forms NF'", {
          'ID Ordem': id,
          'Data da ordem': date,
          Data: format(new Date(), 'dd/MM/yyyy'),
          'Valor da ordem': orderValue,
        });
        break;
      default:
        break;
    }
    setOrderIdToUpdate(id);
    return setConfirmForms(true);
  };

  const handleConfirmForms = async () => {
    setConfirmForms(false);
    await PaymentOrderAPICMS.updateTasksStatus({ orders: [orderIdToUpdate], status: 'f' });
    refetchOrderPayments();
    return window.open(PARTNER_FORMS_LINK, '_blank');
  };

  const handleCancelOrder = (id, orderValue, date) => {
    Mixpanel.track("Clicou 'Financeiro - Cancelar ordem'", {
      'ID Ordem': id,
      'Data da ordem': date,
      Data: format(new Date(), 'dd/MM/yyyy'),
      'Valor da ordem': orderValue,
    });
    setOrderIdToCancel(id);
    return setConfirmCancelOrder(true);
  };

  const handleConfirmCancelOrder = async () => {
    setConfirmCancelOrder(false);
    await PaymentOrderAPICMS.delete(orderIdToCancel);
    return await refetchOrderPayments();
  };

  function exportTasks() {
    setLoadingExport(true);
    ExportReport.create().then(({ data }) => {
      ExportReport.checkReport(data.id)
        .then(({ data }) => {
          if (data.results[0].report_file) {
            downloadPDF(
              data.results[0].report_file,
              data.results[0].report_file.split('external/')[1].split('.csv')[0],
              true,
            );
          }
          setLoadingExport(false);
        })
        .catch(() => {
          setLoadingExport(false);
          toast.error('Erro ao gerar o Report.');
        });
    });
  }

  function openLessonPartner() {
    window.open('https://app.medway.com.br/lesson_module/27054', '_blank');
  }

  const handleOrderError = async (id, orderValue, date) => {
    Mixpanel.track("Clicou 'Financeiro - Erro em ordem'", {
      'ID Ordem': id,
      'Data da ordem': date,
      Data: format(new Date(), 'dd/MM/yyyy'),
      'Valor da ordem': orderValue,
    });

    setErrorOrderDataId(id);
    setShowErrorOrderModal(true);
  };

  const handleErrorOrder = async () => {
    setShowErrorOrderModal(false);
    if (!errorOrderDataId) return toast.error('Erro. Tente novamente mais tarde.');

    await PaymentOrderAPICMS.updateTasksStatus({ orders: [errorOrderDataId], status: 'e' })
      .then(() => {
        toast.success('Ordem alterada para o status de erro');
        PaymentOrderAPICMS.sendEmail(errorOrderDataId, { message: orderErrorMessage });
      })
      .catch(() => toast.error('Erro ao alterar status da ordem. Tente novamente mais tarde.'))
      .finally(() => setErrorOrderDataId(null));
    return await refetchOrderPayments();
  };

  function handleOrderErrorMessage(event) {
    event.persist();
    setOrderErrorMessage(event.target.value);
  }

  const handleScroll = () => {
    if (isEmpty && isEmpty.current) {
      return setVisible(true);
    }
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

    if (currentScroll > 0 && position <= currentScroll) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    position = currentScroll;
  };

  const handleAction = (event) => {
    setSelectedSecondAction(null);
    setSelectedAction(event.target.value);

    switch (event.target.value) {
      case 'delete':
        setActionModalInfo({
          title: 'Excluir atividades',
          message:
            'Eu confirmo que estou excluindo as atividades selecionadas. Essa ação não poderá ser revertida.',
          buttonText: 'Excluir',
          icon: <S.StyledDeleteIcon />,
        });
        break;
      case 'change_multiplier':
        setActionModalInfo({
          title: 'Alterar multiplicador',
          message:
            'Eu confirmo que estou alterando o multiplicador de todas atividades selecionadas.',
          buttonText: 'Alterar',
          icon: <ExchanegIcon />,
        });
        break;
      case 'change_status':
        setActionModalInfo({
          title: 'Alterar status',
          message: 'Eu confirmo que estou alterando o status de todas atividades selecionadas.',
          buttonText: 'Alterar',
          icon: <ExchanegIcon />,
        });
        break;
      case 'change_quality':
        setActionModalInfo({
          title: 'Alterar qualidade',
          message: 'Eu confirmo que estou alterando a qualidade de todas atividades selecionadas.',
          buttonText: 'Alterar',
          icon: <ExchanegIcon />,
        });
        break;
      case 'ignore_penalty':
        setActionModalInfo({
          title: 'Desconsiderar multa',
          message: 'Eu confirmo que estou desconsiderando as multas das atividades selecionadas.',
          buttonText: 'Desconsiderar multa',
          icon: <ExchanegIcon />,
        });
        break;
      case 'price_override':
        setActionModalInfo({
          title: 'Atualizar valor da atividade',
          message: 'Eu confirmo que estou atualizando o valor das atividades selecionadas.',
          buttonText: 'Atualizar valores',
          icon: <ExchanegIcon />,
        });
        break;
      default:
        setActionModalInfo({
          title: '',
          message: '',
          buttonText: '',
          icon: '',
        });
        break;
    }
  };

  const clearSelections = () => {
    setChildrenChecked([]);
    setHelperChecked([]);
  };

  const bulkUpdateActions = async (action, value, successMessage, errorMessage) => {
    await TaskAPICMS.bulkUpdate({
      ids: childrenChecked,
      action: action,
      ...(value && { value: value }),
    })
      .then(() => {
        toast.success(successMessage);
        if (action === 'delete' || action === 'status') {
          if (selectedAggregation === 'Mês') {
            refetchTasksByMonth();
          } else if (selectedAggregation === 'Ano') {
            refetchTasksByYear();
          }
        }

        refetchTasks();
      })
      .catch(() => toast.error(errorMessage))
      .finally(() => {
        clearSelections();
      });
  };

  const handleSaveAction = () => {
    if (!selectedAction) {
      return toast.error('Selecione uma ação!');
    }

    setShowActionConfirmationModal(true);
    switch (selectedAction) {
      case 'change_multiplier':
        if (!selectedSecondAction) {
          return toast.error('Selecione o multiplicador para alteração.');
        }
        break;
      case 'change_status':
        if (!selectedSecondAction) {
          return toast.error('Selecione o novo status para alteração.');
        }
        break;
      case 'change_quality':
        if (!selectedSecondAction) {
          return toast.error('Selecione a qualidade para alteração.');
        }
        break;
      case 'price_override':
        if (!selectedSecondAction) {
          return toast.error('Selecione o valor para alteração.');
        }
        break;
      case 'ignore_penalty':
      case 'delete':
      default:
        break;
    }
  };

  const handleBulkAction = () => {
    setShowActionConfirmationModal(false);
    switch (selectedAction) {
      case 'delete':
        bulkUpdateActions(
          'delete',
          null,
          'Atividades deletadas com sucesso.',
          'Erro ao deletar atividades. Tente novamente mais tarde.',
        );
        break;
      case 'change_multiplier':
        if (!selectedSecondAction) {
          return toast.error('Selecione a nova data para alteração.');
        }
        bulkUpdateActions(
          'multiplier',
          selectedSecondAction,
          'Multiplicador atualizado com sucesso.',
          'Erro ao atualizar multiplicador. Tente novamente mais tarde.',
        );
        break;
      case 'change_status':
        if (!selectedSecondAction) {
          return toast.error('Selecione o novo status para alteração.');
        }
        bulkUpdateActions(
          'status',
          selectedSecondAction,
          'Status atualizados com sucesso.',
          'Erro ao atualizar status. Tente novamente mais tarde.',
        );
        break;
      case 'change_quality':
        if (!selectedSecondAction) {
          return toast.error('Selecione o novo responsável para alteração');
        }
        bulkUpdateActions(
          'quality',
          selectedSecondAction.toString(),
          'Qualidade atualizada com sucesso.',
          'Erro ao atualizar qualidade. Tente novamente mais tarde.',
        );
        break;
      case 'ignore_penalty':
        bulkUpdateActions(
          'ignore_penalty',
          null,
          'Multa desconsiderada com sucesso.',
          'Erro ao alterar multa. Tente novamente mais tarde.',
        );
        break;
      case 'price_override':
        bulkUpdateActions(
          'price_override',
          selectedSecondAction,
          'Valores atualizados com sucesso.',
          'Erro ao atualizar valores. Tente novamente mais tarde.',
        );
        break;
      default:
        break;
    }
  };

  const checkAllPerHelper = (event, id, activities) => {
    const idsToUpdate = activities?.map((task) => task.id);
    if (!idsToUpdate) return;

    if (!event) {
      const updatedHelperChecked = helperChecked.filter((value) => value !== id);
      setHelperChecked(updatedHelperChecked);

      const updatedIdsChecked = childrenChecked.filter((child) => {
        return !idsToUpdate.includes(child);
      });

      setChildrenChecked(updatedIdsChecked);
      return;
    }

    const noDuplicates = idsToUpdate.filter((val) => !childrenChecked.includes(val));
    setChildrenChecked((childrenChecked) => [...childrenChecked, ...noDuplicates]);
    setHelperChecked((helperChecked) => [...helperChecked, id]);
  };

  const checkChildPerId = (event, id, helperId, activities, identifier) => {
    const activitiesIds = activities.map((activity) => activity.id);

    if (!event) {
      const updatedIdsChecked = childrenChecked.filter((value) => value !== id);
      setChildrenChecked(updatedIdsChecked);

      const updatedHelperChecked = helperChecked.filter((value) => value !== helperId);
      setHelperChecked(updatedHelperChecked);

      const updatedActivitiesIds = activitiesIds.filter((value) =>
        updatedIdsChecked.includes(value),
      );

      setSelectedActivitiesIds({
        ...selectedActivitiesIds,
        [identifier]: updatedActivitiesIds,
      });
      return;
    }

    const allChildrenFromParent = activities?.map((activity) => activity.id);
    const updatedIdsChecked = [...childrenChecked, id];

    if (checkIfArrayContainsAnother(updatedIdsChecked, allChildrenFromParent)) {
      setHelperChecked((helperChecked) => [...helperChecked, helperId]);
    }
    setChildrenChecked((childrenChecked) => [...childrenChecked, id]);

    const updatedActivitiesIds = activitiesIds.filter((value) => updatedIdsChecked.includes(value));

    setSelectedActivitiesIds({
      ...selectedActivitiesIds,
      [identifier]: updatedActivitiesIds,
    });
  };

  const handlePrice = (event) => {
    let inputValue = event.target.value;

    if (isNaN(selectedSecondAction)) return;

    inputValue = inputValue.replace(/[^0-9]/g, '');
    const numericValue = parseFloat(inputValue) / 100;
    setSelectedSecondAction(numericValue);
    const formattedValue = numericValue.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    setSelectedNewPrice(formattedValue);
  };

  useEffect(() => {
    if (!tasksData) return;

    const identifier = `${newParams.responsible}-${
      selectedAggregation === 'Ano' ? undefined : newParams?.ended_at__month
    }-${newParams?.ended_at__year}`;

    const newElement = { [identifier]: tasksData };
    setFetchedTasks({ ...fetchedTasks, ...newElement });
  }, [tasksData]);

  useEffect(() => {
    if (!managePage) return;

    if (helperChecked.length === 0 && childrenChecked.length === 0) {
      return setShowFooter(false);
    }
    setShowFooter(true);
  }, [helperChecked, childrenChecked]);

  useEffect(() => {
    if (largeScreen) return;
    document.addEventListener('scroll', handleScroll, true);
    return () => {
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, [largeScreen]);

  useEffect(() => {
    if (!height) return;
    return setLargeScreen(height > 700);
  }, [height]);

  useLayoutEffect(() => {
    if (!headerRef) return;
    return setHeaderHeight(headerRef.current?.offsetHeight);
  }, [headerRef]);

  useEffect(() => {
    if (!headerRef) return;
    return setHeaderHeight(headerRef.current?.offsetHeight);
  }, [filterChips]);

  useEffect(() => {
    if (!selectedStatus) return;

    const newParams = {
      selectedStatus: STATUS_OPTIONS.find((option) => option.value === selectedStatus),
    };

    populateChips(newParams);
    return setFormsData({
      ...formsData,
      ...newParams,
    });
  }, [selectedStatus]);

  useEffect(() => {
    let count = 0;
    let countSelectedValues = 0;
    let arrayOfSelectedActivities = [];
    let arrayOfActivitiesTasks = [];

    Object.keys(selectedActivitiesIds).forEach((key) => {
      if (isListActivities()) {
        count = count + selectedActivitiesIds[key].length;
      }
      return selectedActivitiesIds[key]?.map((activityId) => {
        return arrayOfSelectedActivities.push(activityId);
      });
    });

    if (isListActivities()) {
      if (waitingRequestFromActivity || objectIsEmpty(childrenActivities)) return;

      Object.keys(childrenActivities).forEach((key) => {
        return childrenActivities[key]?.map((task) => {
          return arrayOfActivitiesTasks.push(task);
        });
      });

      arrayOfActivitiesTasks.map((activity) => {
        if (arrayOfSelectedActivities.includes(activity.id)) {
          countSelectedValues =
            countSelectedValues + (activity?.values_to_pay?.total_calculated || 0);
        }
      });
    } else if (isOrderPayment()) {
      if (orderPaymentData) {
        const orders = Object.keys(selectedActivitiesIds);
        count = orders?.length || 0;

        orderPaymentData.map((order) => {
          return order?.tasks?.map((task) => {
            if (arrayOfSelectedActivities.includes(task.id)) {
              countSelectedValues =
                countSelectedValues + (task.values_to_pay?.total_calculated || 0);
            }
          });
        });
      }
    }

    const selectedCountString = modifyNumberToFormat(countSelectedValues);

    setCardsInfo({ ...cardsInfo, countValues: formatPrice(selectedCountString, true) });
    setVisible(true);
    setSelectedActivitiesCount(count);
    return setDisablePanel(count === 0);
  }, [selectedActivitiesIds, childrenActivities]);

  useEffect(() => {
    populateDashboard();
    if (
      (isListActivities() && activitiesTasks && activitiesTasks?.length === 0) ||
      (isOrderPayment() && orderPaymentData && orderPaymentData?.length === 0)
    ) {
      isEmpty.current = true;
    } else {
      isEmpty.current = false;
    }
  }, [activitiesTasks, orderPaymentData]);

  useEffect(() => {
    if (!tasksDataByMonth) return;
    setActivitiesTasks(tasksDataByMonth);
  }, [tasksDataByMonth]);

  useEffect(() => {
    if (!tasksDataByYear) return;
    setActivitiesTasks(tasksDataByYear);
  }, [tasksDataByYear]);

  const renderNextAction = () => {
    switch (selectedAction) {
      case 'delete':
        return <></>;
      case 'change_multiplier':
        return (
          <S.SecondSelectContainer>
            <SelectComponentMaterialUi
              label="Multiplicador"
              placeholder="Multiplicador"
              values={MULTIPLIER}
              value={selectedSecondAction}
              setSelectedValue={(event) => setSelectedSecondAction(event.target.value)}
              multiple={false}
              id={'select_multiplier'}
              size="small"
            />
          </S.SecondSelectContainer>
        );
      case 'change_status':
        const statusOptions = STATUS_OPTIONS.filter((status) => status.value === 'a');
        return (
          <S.SecondSelectContainer>
            <SelectComponentMaterialUi
              label="Status"
              placeholder="Status"
              values={statusOptions}
              value={selectedSecondAction}
              setSelectedValue={(event) => setSelectedSecondAction(event.target.value)}
              multiple={false}
              id={'select_status'}
              size="small"
            />
          </S.SecondSelectContainer>
        );
      case 'change_quality':
        return (
          <S.SecondSelectContainer>
            <SelectComponentMaterialUi
              label="Qualidade"
              placeholder="Qualidade"
              values={QUALITY}
              value={selectedSecondAction}
              setSelectedValue={(event) => setSelectedSecondAction(event.target.value)}
              multiple={false}
              id={'select_quality'}
              size="small"
            />
          </S.SecondSelectContainer>
        );
      case 'price_override':
        return (
          <S.SecondSelectContainer>
            <S.InputStyle>
              <input
                type="text"
                onChange={handlePrice}
                placeholder="Novo valor"
                value={selectedNewPrice && selectedNewPrice}
              />
            </S.InputStyle>
          </S.SecondSelectContainer>
        );
      default:
        return;
    }
  };

  return (
    <>
      {largeScreen && (
        <HeaderComponent
          title="Financeiro"
          description="Gerencie e crie solicitações de pagamento"
          noMargin
        />
      )}
      <S.Wrap>
        <S.Header hasHeader={largeScreen} ref={headerRef}>
          <S.HeaderContainer>
            <S.HeaderElementsContainer>
              <SearchInputMaterialUi
                label="Buscar por palavra"
                id="search"
                setSelectedValue={setSearch}
                handleBlur={handleSearch}
                value={search}
                size="small"
                handleKeydown={handleSearchKey}
                textfieldStyle={{ width: '380px' }}
              />
              <FilterDropdown
                formsData={formsData}
                setFormsData={setFormsData}
                handleFilter={handleFilter}
                clearFilters={handleClear}
                setFilterChips={setFilterChips}
                managePage={managePage}
              />
            </S.HeaderElementsContainer>
            {isListActivities() && (
              <S.HeaderElementsContainer>
                <ButtonTextWithPopper
                  title={`Agregar por: ${selectedAggregation}`}
                  icon={<IoChevronDownSharp size={16} color="#00205B" />}
                >
                  <S.DropdownMenu>
                    {AGGREGATION.map((item) => {
                      return (
                        <S.DropdownMenuItem onClick={() => handleAggregation(item)}>
                          {item.label}
                        </S.DropdownMenuItem>
                      );
                    })}
                  </S.DropdownMenu>
                </ButtonTextWithPopper>
                <ButtonTextWithPopper
                  title={`Ordenar por: ${selectedOrdering?.label || ''}`}
                  icon={<IoChevronDownSharp size={16} color="#00205B" />}
                >
                  <S.DropdownMenu>
                    {ORDERING.map((item) => {
                      return (
                        <S.DropdownMenuItem onClick={() => handleOrdenation(item)}>
                          {item.label}
                        </S.DropdownMenuItem>
                      );
                    })}
                  </S.DropdownMenu>
                </ButtonTextWithPopper>
              </S.HeaderElementsContainer>
            )}
          </S.HeaderContainer>
          {filterChips.length > 0 && (
            <S.HeaderElementsContainer>
              <FilterChips data={filterChips} handleDelete={deleteChipFilter} />
            </S.HeaderElementsContainer>
          )}
        </S.Header>
        <S.DashboardContainer
          hasHeader={largeScreen}
          headerHeight={headerHeight}
          key={`dashboard-container-${largeScreen}`}
          visible={visible}
        >
          <DashboardCards size={largeScreen ? 'large' : 'small'} data={cardsData} user={user} />
          <HeaderList
            title={isListActivities() ? 'Solicitações de Pagamento' : 'Ordens de Pagamento'}
          >
            {managePage ? (
              <Button loading={loadingExport} onClick={() => exportTasks()}>
                Exportar relatório gerencial
              </Button>
            ) : (
              <Button onClick={() => openLessonPartner()}>Ainda tenho dúvidas</Button>
            )}
          </HeaderList>
        </S.DashboardContainer>

        <S.Container hasChips={filterChips.length > 0}>
          <S.Content>
            {isListActivities() && (
              <>
                {fetchingTasksDataByMonth || fetchingTasksDataByYear ? (
                  <Loader />
                ) : (
                  <ListActivities
                    activitiesData={activitiesTasks}
                    handleOpenChat={handleOpenChat}
                    handleOpenOffcanvas={handleOpenOffcanvas}
                    selectedIds={selectedActivitiesIds}
                    setSelectedIds={setSelectedActivitiesIds}
                    managePage={managePage}
                    selectedStatus={listParams.status}
                    handleChangeMultiplier={handleChangeMultiplier}
                    helperChecked={helperChecked}
                    checkAll={checkAllPerHelper}
                    childrenChecked={childrenChecked}
                    checkChild={checkChildPerId}
                    setWaitingRequestFromActivity={setWaitingRequestFromActivity}
                    setChildrenActivities={setChildrenActivities}
                    fetchedTasks={fetchedTasks}
                    fetchingTasksData={fetchingTasksData}
                    getTaskData={getTaskData}
                    expandedSummary={expandedSummary}
                    setExpandedSummary={setExpandedSummary}
                  />
                )}
              </>
            )}
            {isOrderPayment() && (
              <>
                {fetchingOrderPaymentData ? (
                  <Loader />
                ) : (
                  <OrderPayments
                    orderPaymentsData={orderPaymentData}
                    handleOrderChat={handleOpenChat}
                    handleOrderOffcanvas={handleOpenOffcanvas}
                    managePage={managePage}
                    selectedStatus={listOrderParams.status}
                    selectedIds={selectedActivitiesIds}
                    setSelectedIds={setSelectedActivitiesIds}
                    fillForms={handleForms}
                    cancelOrder={handleCancelOrder}
                    orderError={handleOrderError}
                  />
                )}
              </>
            )}
          </S.Content>
        </S.Container>
        {showFooter && (
          <S.Footer>
            <S.FooterLeft>
              <S.SelectContainer>
                <SelectComponentMaterialUi
                  label="Executar ação"
                  placeholder="Executar ação"
                  values={ACTIONS_LIST}
                  selectedValue={selectedAction}
                  setSelectedValue={handleAction}
                  multiple={false}
                  id={'select_action'}
                  size="small"
                  limitWidth={true}
                />
              </S.SelectContainer>
              {selectedAction && renderNextAction()}
              <S.SelectedLabel>
                <b>{childrenChecked.length}</b>
                {childrenChecked.length === 1
                  ? ' atividade selecionada'
                  : ' atividades selecionadas'}
              </S.SelectedLabel>
            </S.FooterLeft>
            <S.FooterButtonsContainer>
              <S.ClearButton onClick={clearSelections}>Limpar seleção</S.ClearButton>
              <S.SaveButton onClick={handleSaveAction}>
                <S.StyledArrowFoward />
              </S.SaveButton>
            </S.FooterButtonsContainer>
          </S.Footer>
        )}
      </S.Wrap>
      {openOffcanvas && (
        <OffCanvas
          show={openOffcanvas}
          close={() => setOpenOffcanvas(false)}
          activityId={offcanvasId}
          handleChangeStatus={handleChangeStatus}
          managePage={managePage}
          handleChangeQuality={handleChangeQuality}
          changeStatusPartner={changeStatusPartner}
        />
      )}
      {openOffcanvasMessaging && (
        <OffCanvasMessaging
          show={openOffcanvasMessaging}
          close={() => handleCloseOffCanvasMessaging()}
          activityId={offcanvasId}
          user={user}
        />
      )}
      <ModalConfirmation
        showModal={showDataModal}
        handleClose={handleDataModalClose}
        title="Data de pagamento"
        message={
          <S.ModalContent>
            <S.ModalDescription>
              Selecione a data para pagamento das ordens selecionadas
            </S.ModalDescription>
            <DatePicker
              label="Data"
              dateFormat="dd/MM/yyyy"
              value={paymentDate}
              handleChange={setPaymentDate}
            />
          </S.ModalContent>
        }
        actionText={
          <>
            {'Confirmar '} <IoCheckmarkDoneSharp size={16} color={'#fff'} />
          </>
        }
        handleAction={handleConfirmDate}
      />
      <ModalConfirmation
        showModal={confirmCancelOrder}
        handleClose={() => setConfirmCancelOrder(false)}
        title="Cancelar ordem"
        message={'Você tem certeza que deseja cancelar a ordem?'}
        actionText={
          <>
            {'Confirmar '} <IoCheckmarkDoneSharp size={16} color={'#fff'} />
          </>
        }
        handleAction={handleConfirmCancelOrder}
      />
      <ModalConfirmation
        showModal={confirmForms}
        handleClose={() => setConfirmForms(false)}
        title="Envio de Nota Fiscal"
        message={`Eu estou ciente que devo preencher o formulário com as informações da nota fiscal. Ao confirmar o status da sua ordem será alterado para em análise.
Caso haja alguma incoerência nas informações fornecidas durante a análise, a ordem de pagamento ficará com o status "erro na ordem" e o formulário deverá ser reenviado.`}
        actionText={
          <>
            {'Confirmar '} <IoCheckmarkDoneSharp size={16} color={'#fff'} />
          </>
        }
        handleAction={handleConfirmForms}
      />
      <ModalConfirmation
        showModal={showErrorOrderModal}
        handleClose={() => setShowErrorOrderModal(false)}
        title="Erro em ordem"
        message={
          <S.ModalContent>
            <S.ModalDescription>
              Descreva brevemente erro encontrado para parceiro efetuar a correção
            </S.ModalDescription>
            <TextAreaComponent
              type="text"
              placeholder="Escreva aqui"
              onChange={(event) => handleOrderErrorMessage(event)}
              value={orderErrorMessage}
              height={110}
            />
          </S.ModalContent>
        }
        actionText={
          <>
            {'Enviar '} <S.StyledSendIcon />
          </>
        }
        handleAction={handleErrorOrder}
      />
      {showActionConfirmationModal && (
        <ModalConfirmation
          showModal={showActionConfirmationModal}
          handleClose={() => setShowActionConfirmationModal(false)}
          title={actionModalInfo.title}
          message={actionModalInfo.message}
          actionText={
            <>
              {actionModalInfo.icon} {actionModalInfo.buttonText}
            </>
          }
          handleAction={handleBulkAction}
        />
      )}
    </>
  );
}

export default Financial;
