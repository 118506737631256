import React, { useState, useEffect } from 'react';

import ModalFilter from '~/components/ModalFilter';

import { useGetFlashcardDeck } from '~/hooks-querys/flashcard_deck';
import { useResidencyDegree } from '~/hooks-querys/residencydegree';

import Pagination from '~/components/Pagination';
import Loader from '~/components/Loader';
import Filter from './Filter';
import FlashcardItem from './FlashcardItem';
import { transformOptions } from './util';
import * as S from './styles';

let isFirstRender = true;

function FlashcardSearch({ show, close }) {
  const [residencyDegrees, setResidencyDegrees] = useState([]);
  const [dispatchFilter, setDispatchFilter] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasPagination, setHasPagination] = useState(false);
  const [filter, setFilter] = useState({
    page: 1,
  });

  const { data: dataFlashcardDeck, isFetching: loadingDataFlashcardDeck } = useGetFlashcardDeck(
    {
      ordering: 'name',
      ...(filter?.search && { search: filter.search }),
      ...(filter?.id && { id: filter.id }),
      ...(filter?.tag && { tag: filter.tag.value }),
      ...(filter?.residency_degree && { residency_degree: filter.residency_degree.value }),
      ...(filter?.institution && { institutions: filter.institution.value }),
      ...(filter?.status && { status: true }),
      page: currentPage,
      is_lesson_module_item: true,
    },
    {
      config: {
        enabled: dispatchFilter,
      },
    },
  );

  const { data: residencyDegreeData, isLoading: loadingResidencyDegree } = useResidencyDegree({
    no_page: true,
    ordering: 'abbreviation',
  });

  useEffect(() => {
    if (!dataFlashcardDeck) return;
    setSearchResults(dataFlashcardDeck?.results);
    setTotalPages(Math.ceil(dataFlashcardDeck.count / 30));
    setHasPagination(!!dataFlashcardDeck.next || !!dataFlashcardDeck.previous);
    setDispatchFilter(false);
  }, [dataFlashcardDeck]);

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const onCleanFilter = () => {
    const defaultResidencyDegree = residencyDegrees.find((residencyDegree) =>
      residencyDegree.abbreviation.includes('R1'),
    );
    setFilter({
      residency_degree: defaultResidencyDegree,
      search: '',
      id: '',
      speciality: null,
      institution: null,
      tag: null,
      status: null,
    });
  };

  const onHandleFilter = () => {
    setDispatchFilter(true);
  };

  const onAddItems = () => {
    close(selectedItems);
  };

  useEffect(() => {
    if (!residencyDegreeData) return;
    setResidencyDegrees((residencyDegrees) => [
      ...residencyDegrees,
      ...residencyDegreeData.map(transformOptions),
    ]);
  }, [residencyDegreeData]);

  useEffect(() => {
    if (residencyDegrees.length === 0) return;

    const defaultResidencyDegree = residencyDegrees.find((residencyDegree) =>
      residencyDegree.abbreviation.includes('R1'),
    );

    setFilter({ ...filter, residency_degree: defaultResidencyDegree });
  }, [residencyDegrees]);

  useEffect(() => {
    if (isFirstRender) {
      isFirstRender = false;
      return;
    }
    setDispatchFilter(true);
  }, [currentPage]);

  useEffect(() => {
    setDispatchFilter(false);
  }, [filter?.search]);

  function renderList() {
    return (
      <S.Container>
        {loadingDataFlashcardDeck ? (
          <Loader />
        ) : (
          <>
            {searchResults?.length > 0 && (
              <>
                <S.ContainerItems>
                  {searchResults.map((item, key) => (
                    <FlashcardItem
                      key={key}
                      flashcard={item}
                      selectedItems={selectedItems}
                      setSelectedItems={setSelectedItems}
                    />
                  ))}
                </S.ContainerItems>
                <S.ContainerPagination>
                  {searchResults?.length > 0 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      backPage={goToPreviousPage}
                      nextPage={goToNextPage}
                      setPage={setCurrentPage}
                    />
                  )}
                </S.ContainerPagination>
              </>
            )}
          </>
        )}
      </S.Container>
    );
  }

  return (
    <ModalFilter
      show={show}
      close={close}
      title="Buscar decks de flashcards"
      buttonTitle="Adicionar decks"
      showFooter={searchResults?.length > 0}
      onCleanFilter={onCleanFilter}
      onHandleFilter={onHandleFilter}
      onAddItems={onAddItems}
      childrenFilter={
        <Filter
          filter={filter}
          setFilter={setFilter}
          residencyDegrees={residencyDegrees}
          loadingResidencyDegree={loadingResidencyDegree}
        />
      }
      childrenList={renderList()}
    />
  );
}

export default FlashcardSearch;
