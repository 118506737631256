import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import Axios from 'axios';
import history from '~/services/history';
import { SelectStyle } from '~/pages/_layouts/default/styles';
import Select from 'react-select';
import HeaderComponent from '~/components/HeaderComponent';
import Loader from '~/components/Loader';
import LoadingSpinner from '~/components/LoadingSpinner';
import SelectWithPagination from '~/components/SelectWithPagination';
import MultiselectAutocomplete from '~/components/MultiselectAutocomplete';
import SelectionQuestions from '~components/SelectionQuestions';
import DatePicker from '~/components/DatePickerComponent';
import ImportQuestions from '~/components/ImportQuestions';
import SearchQuestionsModal from '~/components/SearchQuestionsModal';
import RadioButton from '~/components/RadioButton';
import ListSpecialitiesAndQuestions from '~/components/ListSpecialitiesAndQuestions';
import GreenBorder from '~/components/GreenBorder';

import { saveFilters, saveShowMoreFilters } from '~/store/modules/questionFilters/actions';
import { FILTERS_INITIAL_STATE } from '~/store/modules/questionFilters/reducer';

import { useTrackDetail } from '~/hooks-querys/tracks';
import { useSearchQuestionsModalGet } from '~/hooks-querys/searchQuestionsModal';
import { useTrackCategory } from '~/hooks-querys/trackCategory';
import { useResidencyDegree } from '~/hooks-querys/residencydegree';
import { useFilterCourse } from '~/hooks-querys/filters';

import LinkLogo from '~/assets/link_question.svg';
import { ReactComponent as RemoveLogo } from '~/assets/remove_question.svg';
import DragIco from '~/assets/drag_ico.svg';
import InformationIcon from '~/assets/information_logo.svg';

import { InstitutionAPICMS, TrackAPICMS } from '~/services/apiCallsCMS';
import { MdArrowForward, MdArrowBack } from 'react-icons/md';
import { Mixpanel } from '~/services/analytics';

import * as S from './styles';

function ConfigChallenge() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const updatedQuestions = useSelector((state) => state.questionFilters.filtered_questions) || [];
  const [loading, setLoading] = useState(false);
  const [listQuestions, setlistQuestions] = useState();
  const [trackType, setTrackType] = useState('o');
  const [numberOptions, setNumberOptions] = useState();
  const [courses, setCourses] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [residencyDegrees, setResidencyDegrees] = useState([]);
  const [categorysTrack, setCategorysTrack] = useState(null);
  const [residencyDegree, setResidencyDegree] = useState(null);
  const [trackTypeChallenge, setTrackTypeChallenge] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [csvFile, setCsvFile] = useState();
  const [showModal, setShowModal] = useState(false);
  const [imageTrack, setImageTrack] = useState(null);
  const [fileName, setFileName] = useState('Imagem de capa');
  const [pdfFileName, setPdfFileName] = useState('Arquivos');
  const [nameTrack, setNameTrack] = useState(null);
  const [yearTrack, setYearTrack] = useState();
  const [yearScore, setYearScore] = useState();
  const [filesTrack, setFilesTrack] = useState();
  const [, setNumberQuestions] = useState();
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [disabledImport, setDisabledImport] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [questionsChallenge, setQuestionsChallenge] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [showQuestionsModal, setShowQuestionsModal] = useState(false);
  const [reviewMode, setReviewMode] = useState(false);
  const [newTrackId, setNewTrackId] = useState(null);
  const [newCsvFile, setNewCsvFile] = useState(null);
  const [allQuestions, setAllQuestions] = useState([]);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [loadingAllQuestions, setLoadingAllQuestions] = useState(false);
  const [questionsCsvInfo, setQuestionsCsvInfo] = useState({
    totalQuestions: 0,
    currentPage: 1,
    hasNext: false,
    totalPages: 1,
  });

  const { data: challenge, isFetching: fetchingChallenge } = useTrackDetail(
    { id },
    {
      config: {
        enabled: !!id,
      },
    },
  );

  const {
    data: dataQuestionsChallenge,
    isFetching: isLoadingQuestionsChallenge,
    refetch: refetchListQuestionsChallenge,
  } = useSearchQuestionsModalGet(
    { track: id || newTrackId, action: 'text', ordering: 'question_number', no_page: true },
    {
      config: {
        enabled: !!id,
      },
    },
  );

  const { data: residencyDegreeData, isLoading: loadingResidencyDegree } = useResidencyDegree({
    no_page: true,
    ordering: 'abbreviation',
  });

  useEffect(() => {
    if (dataQuestionsChallenge) {
      setQuestionsChallenge(dataQuestionsChallenge);
    }
  }, [dataQuestionsChallenge]);

  const { data: dataTrackCategory, isLoading: loadingTrackCategory } = useTrackCategory(
    {
      ordering: 'abbreviation',
    },
    {
      config: {
        enabled: categorys.length === 0,
      },
    },
  );

  useEffect(() => {
    if (!dataTrackCategory && !dataTrackCategory?.results) return;

    setCategorys(
      dataTrackCategory.results.map((element) => {
        return { label: element.name, value: element.id, key: element.id };
      }),
    );
  }, [dataTrackCategory]);

  const { data: coursesData, isLoading: loadingCourses } = useFilterCourse(
    {
      ordering: 'name',
      no_page: 1,
    },
    {
      config: {
        enabled: courses.length === 0,
      },
    },
  );

  const transformCoursesOptions = (course) => {
    if (!course.id || !course.name) return;
    return {
      label: course.name,
      key: course.id,
      value: course.id,
      course,
    };
  };

  useEffect(() => {
    if (!coursesData) return;

    setCourses(coursesData.map(transformCoursesOptions));
  }, [coursesData]);

  useEffect(() => {
    if (!residencyDegreeData) return;
    setResidencyDegrees(residencyDegreeData.map(transformCoursesOptions));
  }, [residencyDegreeData]);

  const filteredCourses = async (search) => {
    if (!search) {
      return courses;
    }
    return courses.filter((item) => search.includes(item.label));
  };

  function handleImageTrack(event) {
    if (!event.target?.files || event.target?.files.length === 0) {
      return;
    }
    const file = event.target?.files[0];
    setImageTrack(file);
    setFileName(file.name);
  }

  const handleAddCourses = (id, review) => {
    const source = Axios.CancelToken.source();
    const data = {
      courses: selectedCourses.map((item) => item.value),
    };

    TrackAPICMS.addCourses(id, data, source.token).then(async (_result) => {
      if (review) {
        toast.success('Atualização das questões realizada com sucesso.');
        history.push(`/exam/${newTrackId}`);
      } else {
        toast.success(`Simulado ${id ? 'atualizado' : 'criado'} com sucesso`);
        setLoading(false);
        setDisabled(false);
        setReviewMode(true);
        setNewTrackId(id);
      }
    });
  };

  useEffect(() => {
    if (!newTrackId) return;

    refetchListQuestionsChallenge();
  }, [newTrackId]);

  const handleCreateQuestions = (track) => {
    const source = Axios.CancelToken.source();
    const questionsData = {
      track_category: track.track_category,
      residency_degree: track.residency_degree,
      year: parseInt(yearTrack, 10),
      ...(trackType === 'o' && { options_number: numberOptions.value }),
      speciality_order: listQuestions,
      question_type: trackType,
    };

    TrackAPICMS.create_questions(track.id, questionsData, source.token).then(async (_result) => {
      handleAddCourses(track.id, false);
    });
  };

  function handleCreateTrack(data, haveNoNewQuestions) {
    let trackImageCreated = null;
    let trackThumbnailCreated = null;
    let fileCreated = null;
    const source = Axios.CancelToken.source();
    setDisabled(true);
    setLoading(true);
    TrackAPICMS.create(data, source.token)
      .then(async (res) => {
        if (imageTrack) {
          const result = await TrackAPICMS.updateFile(res.data.id, { track_image: imageTrack });
          trackImageCreated = result.data.track_image;
          trackThumbnailCreated = result.data.thumbnail;
        }
        if (filesTrack) {
          const obj = {
            track_id: res.data.id,
            file: filesTrack,
          };
          const result = await TrackAPICMS.files_track(obj, source.token);
          fileCreated = result.data.file;
        }
        Mixpanel.track("Clicou 'Nova prova - Botão criar nova prova'", {
          'Nome da prova': nameTrack,
          'Tipo de prova': trackType,
          Jornada: categorysTrack,
          Ano: parseInt(yearTrack, 10),
          Instituição: selectedInstitution?.label,
          'Imagem da trilha': trackImageCreated,
          'Thumb da prova': trackThumbnailCreated,
          Arquivos: fileCreated,
          'Configuração das especialidades': `Cir (${listQuestions.cir})/CM (${listQuestions.cm})/Ped (${listQuestions.prev})/GO (${listQuestions.go})/No_speciality (${listQuestions.no_speciality}))`,
          Alternativas: numberOptions?.label,
          'Nome do curso': selectedCourses.map((item) => item.label),
        });
        if (!haveNoNewQuestions) {
          handleCreateQuestions(res.data);
        } else {
          handleAddCourses(res.data.id, false);
        }
      })
      .catch(() => {
        setDisabled(false);
        setLoading(false);
        toast.error(`Erro na criação do simulado.`);
      });
  }

  function handleUpdateTrack(data, id, haveNoNewQuestions) {
    const source = Axios.CancelToken.source();
    TrackAPICMS.update(id, data, source.token).then(async (res) => {
      if (imageTrack) {
        await TrackAPICMS.updateFile(res.data.id, { track_image: imageTrack });
      }
      if (filesTrack) {
        const obj = {
          track_id: res.data.id,
          file: filesTrack,
        };
        await TrackAPICMS.files_track(obj, source.token);
      }
      if (!haveNoNewQuestions) {
        handleCreateQuestions(res.data);
      } else {
        handleAddCourses(res.data.id, false);
      }
    });
  }

  const handleYear = (event) => {
    setYearTrack(event.target.value);
  };
  const handleYearScore = (event) => {
    setYearScore(event.target.value);
  };
  const handleType = (event) => {
    setTrackType(event.target.value);
  };

  function handleFiles(event) {
    setFilesTrack(event.target.files[0]);
    setPdfFileName(event.target.files[0].name);
  }

  function updateQuestionsOrder() {
    if (!newTrackId) return;
    const finalQuestionsIds = questionsChallenge?.map((item) => item.id) || [];
    if (finalQuestionsIds) {
      const source = Axios.CancelToken.source();
      TrackAPICMS.updateQuestions(newTrackId, { ids: finalQuestionsIds }, source.token)
        .then(async () => {
          handleAddCourses(newTrackId, true);
        })
        .catch(() => {
          toast.error('Houve um problema na atualização das questões do simulado.');
        });
    }
  }

  function validationForm() {
    if (loading) return;
    const haveNoNewQuestions = Object.values(listQuestions).every((value) => value === undefined);
    if (!nameTrack) {
      toast.error('Digite um nome para o simulado');
      return;
    }
    if (!trackTypeChallenge) {
      toast.error('Selecione o tipo do simulado');
      return;
    }

    if (!categorysTrack) {
      toast.error('Selecione a jornada');
      return;
    }
    if (!residencyDegree) {
      toast.error('Selecione o nível de residência');
      return;
    }
    if (!yearTrack || yearTrack < 2010 || yearTrack > 2026) {
      toast.error('Selecione um ano válido');
      return;
    }
    if (trackType === 'o' && !numberOptions && !haveNoNewQuestions) {
      toast.error('Selecione número de alternativas ou remova as questões novas');
      return;
    }

    const combinedQuestions = [
      ...(questionsChallenge?.map((item) => item.id) || []),
      ...allQuestions.map((item) => item.id),
    ];

    const data = {
      name: nameTrack,
      track_category: categorysTrack.value,
      track_type: trackTypeChallenge,
      year: parseInt(yearTrack, 10),
      institution_score: selectedInstitution && selectedInstitution.value,
      year_contest: yearScore && yearScore,
      start_availability: startTime && new Date(startTime).toISOString(),
      available_deadline: endTime && new Date(endTime).toISOString(),
      questions: combinedQuestions,
      residency_degree: residencyDegree.value,
    };

    if (challenge) {
      handleUpdateTrack(data, challenge.id, haveNoNewQuestions);
    } else {
      data['enabled'] = false;
      handleCreateTrack(data, haveNoNewQuestions);
    }
  }

  useEffect(() => {
    if (fetchingChallenge || !challenge) return;

    setNameTrack(challenge.name);
    setYearTrack(challenge.year);
    setFileName(
      challenge.track_image
        ? challenge.track_image.split('/challenge/')[1].split('?')[0].substring(0, 70)
        : '',
    );
    const lenFiles = challenge.files.length;
    setPdfFileName(
      lenFiles > 0
        ? challenge.files[lenFiles - 1].split('/no_institution/')[1].split('?')[0].substring(0, 60)
        : '',
    );
    setStartTime(challenge.start_availability);
    setEndTime(challenge.available_deadline);
    setYearScore(challenge.year_contest);
    if (challenge.institution_score) {
      setSelectedInstitution({
        key: challenge.institution_score.id,
        label: challenge.institution_score.name,
        value: challenge.institution_score.id,
      });
    }
    setTrackTypeChallenge(challenge.track_type);
  }, [challenge, fetchingChallenge]);

  useEffect(() => {
    if (!challenge || !categorys) return;

    const matchedCategory = categorys.find((item) => item.value === challenge.track_category);

    if (matchedCategory) {
      setCategorysTrack(matchedCategory);
    }
  }, [challenge, categorys]);

  useEffect(() => {
    if (!challenge || !residencyDegrees) return;
    const matchedResidency = residencyDegrees.find(
      (item) => item.value === challenge.residency_degree,
    );

    if (matchedResidency) {
      setResidencyDegree(matchedResidency);
    }
  }, [challenge, residencyDegrees]);

  const transformOptions = (institution) => {
    if (!institution.id || !institution.name) return;

    return {
      label: institution.state ? `${institution.name} - ${institution.state}` : institution.name,
      key: institution.id,
      value: institution.id,
      institution,
    };
  };

  const loadInstitutions = useCallback(async (search, loadedOptions, { page }) => {
    const { data } = await InstitutionAPICMS.list({
      page,
      search,
      ordering: 'name',
      residency: true,
    });

    return {
      options: data.results.map(transformOptions),
      hasMore: data.next,
      additional: {
        page: page + 1,
      },
    };
  }, []);

  const handleNameSimulated = (event) => {
    setNameTrack(event.target.value);
  };

  const handleCategorys = (event) => {
    setCategorysTrack(event);
  };

  const populateQuestionsInfos = async () => {
    setLoadingQuestions(true);

    try {
      const { data } = await TrackAPICMS.get_questions_csv(newCsvFile, {
        page: questionsCsvInfo.currentPage,
      });

      setQuestionsCsvInfo({
        totalQuestions: data?.count || 0,
        currentPage: questionsCsvInfo.currentPage + 1,
        hasNext: !!data.next,
        totalPages: Math.ceil(data?.count / 30),
      });

      setLoadingQuestions(false);
      setAllQuestions((allQuestions) => [...allQuestions, ...data?.results]);

      if (questionsCsvInfo.currentPage === Math.ceil(data?.count / 30)) {
        setLoadingAllQuestions(false);
      }
    } catch {
      setLoadingQuestions(false);
      setLoadingAllQuestions(false);
    }
  };

  const handleImportButton = () => {
    setShowModal(true);
    Mixpanel.track("Clicou 'Criação de trilhas novas - importar questões'");
  };

  const handleResidencyDegree = (event) => {
    setResidencyDegree(event);
  };

  function handleCsvFile(event) {
    setCsvFile(event);
    setDisabledImport(false);
  }

  function filterSpecility(data, speciality) {
    const datafiltered = data.filter((item) => item.label === speciality);
    if (datafiltered.length > 0) {
      return datafiltered[0].value;
    }
  }

  const setQuestionsSelected = (data) => {
    const specialityOrder = data.map((specialty) => ({
      abbreviation: specialty.label,
      count: specialty.value,
    }));

    setlistQuestions(specialityOrder);
  };

  const handleSelectedOptions = (option) => {
    setSelectedCourses(option);
  };

  function handleQuestions() {
    setLoadingAllQuestions(true);
    setCsvFile(null);
    setAllQuestions([]);
    setQuestionsCsvInfo({
      totalQuestions: 0,
      currentPage: 1,
      hasNext: false,
      totalPages: 1,
    });
    const formData = new FormData();
    formData.append('csv', csvFile);
    setNewCsvFile(formData);
    setShowModal(false);
  }

  function handleRemoveExistingQuestion(question) {
    const list_question = allQuestions.filter((item) => item.id !== question.id);
    setAllQuestions(list_question);
  }

  const handleCloseQuestionsModal = () => {
    dispatch(saveFilters(FILTERS_INITIAL_STATE));
    dispatch(saveShowMoreFilters(false));
    return setShowQuestionsModal(!showQuestionsModal);
  };

  const removeDuplicates = (arrayOfQuestions) => {
    return [...new Set(arrayOfQuestions)];
  };

  useEffect(() => {
    if (loadingQuestions) return;

    if (questionsCsvInfo.hasNext) {
      populateQuestionsInfos();
    }
  }, [allQuestions]);

  useEffect(() => {
    if (!newCsvFile) return;
    populateQuestionsInfos();
  }, [newCsvFile]);

  useEffect(() => {
    if (updatedQuestions?.length <= 0) return;

    const uniqueQuestions = removeDuplicates([...allQuestions, ...updatedQuestions]);
    setAllQuestions(uniqueQuestions);
  }, [updatedQuestions]);

  useEffect(() => {
    const selCourses = challenge?.courses?.map((item) => item.id);
    if (challenge?.courses.length > 0 && courses.length > 0) {
      setSelectedCourses(courses.filter((item) => selCourses.includes(item.key)));
    } else {
      setSelectedCourses([]);
    }
  }, [challenge, courses]);

  useEffect(() => {
    if (fetchingChallenge) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [fetchingChallenge]);

  return (
    <>
      {loading || fetchingChallenge || loadingTrackCategory || loadingCourses ? (
        <Loader />
      ) : (
        <>
          <HeaderComponent title={id ? 'Editar Simulado' : 'Novo Simulado'} />
          <S.Wrap>
            {showModal && (
              <ImportQuestions
                handleQuestions={handleQuestions}
                handleCsvFile={handleCsvFile}
                disabled={disabledImport}
                csvFile={csvFile}
                setShowModal={setShowModal}
                top={2800}
              />
            )}
            <S.Container>
              {reviewMode && (
                <S.ReviewWarning warning style={{ marginBottom: 20 }}>
                  <S.ReviewWarningLogo warning>
                    <img src={InformationIcon} alt="warning" />
                  </S.ReviewWarningLogo>
                  <S.ReviewTextContainer>
                    <S.ReviewMainText>Alteração de configuração</S.ReviewMainText>
                    <S.ReviewSubText>
                      Para fazer alterações nos dados abaixos, acesse a tela de configuração
                    </S.ReviewSubText>
                  </S.ReviewTextContainer>
                </S.ReviewWarning>
              )}
              <S.ContainerNewTrack>
                <S.FormContainer>
                  <GreenBorder />
                  <S.StepContainer>
                    <S.Step>
                      <S.StepOder>1</S.StepOder>
                      <S.StepText>Configuração</S.StepText>
                    </S.Step>
                    <S.RowContainer>
                      <S.Rows>Nome</S.Rows>
                      {reviewMode ? (
                        <S.ReviewText>{nameTrack}</S.ReviewText>
                      ) : (
                        <S.InputStyle>
                          <input
                            id="simulado"
                            type="text"
                            placeholder="Nome do simulado"
                            onChange={handleNameSimulated}
                            value={nameTrack || ''}
                          />
                        </S.InputStyle>
                      )}
                    </S.RowContainer>
                    <S.RowContainer>
                      <S.Rows>Tipo do Simulado</S.Rows>
                      {reviewMode ? (
                        <S.ReviewText>
                          {trackTypeChallenge === 'b' ? 'Simulado' : 'Simulado Intensivo'}
                        </S.ReviewText>
                      ) : (
                        <S.RadioContainer>
                          <RadioButton
                            control={trackTypeChallenge}
                            onChange={(event) => setTrackTypeChallenge(event.target.value)}
                            text="Simulado"
                            value="b"
                          />
                          <RadioButton
                            control={trackTypeChallenge}
                            onChange={(event) => setTrackTypeChallenge(event.target.value)}
                            text="Simulado Intensivo"
                            value="g"
                          />
                        </S.RadioContainer>
                      )}
                    </S.RowContainer>
                    <S.RowContainer>
                      <S.Rows>Tipo de prova</S.Rows>
                      {reviewMode ? (
                        <S.ReviewText>{trackType === 'o' ? 'Objetiva' : 'Discursiva'}</S.ReviewText>
                      ) : (
                        <S.RadioContainer>
                          <RadioButton
                            control={trackType}
                            onChange={(event) => handleType(event)}
                            text="Objetiva"
                            value="o"
                          />
                          <RadioButton
                            control={trackType}
                            onChange={(event) => handleType(event)}
                            text="Discursiva"
                            value="d"
                          />
                        </S.RadioContainer>
                      )}
                    </S.RowContainer>
                    <S.RowContainer>
                      <S.Rows>Jornada</S.Rows>
                      <S.Rows>
                        {reviewMode ? (
                          <S.ReviewText>
                            {
                              categorys.filter(
                                (item) => item.value === parseInt(categorysTrack.value, 10),
                              )[0].label
                            }
                          </S.ReviewText>
                        ) : (
                          <S.SelectContainer>
                            <Select
                              name="journey"
                              placeholder="Selecione"
                              onChange={handleCategorys}
                              options={categorys}
                              styles={SelectStyle}
                              value={categorysTrack}
                            />
                          </S.SelectContainer>
                        )}
                      </S.Rows>
                    </S.RowContainer>
                    <S.RowContainer>
                      <S.Rows>Nível de Residência</S.Rows>
                      <S.Rows>
                        {reviewMode ? (
                          <S.ReviewText>
                            {
                              residencyDegrees.filter(
                                (item) => item.value === parseInt(residencyDegree.value, 10),
                              )[0].label
                            }
                          </S.ReviewText>
                        ) : (
                          <S.SelectContainer>
                            <Select
                              name="residency"
                              placeholder="Selecione"
                              onChange={handleResidencyDegree}
                              options={residencyDegrees}
                              styles={SelectStyle}
                              value={residencyDegree}
                            />
                          </S.SelectContainer>
                        )}
                      </S.Rows>
                    </S.RowContainer>
                    <S.RowContainer>
                      {reviewMode ? (
                        <>
                          <S.Rows>Ano</S.Rows>
                          <S.ReviewText>{yearTrack}</S.ReviewText>
                        </>
                      ) : (
                        <S.InputStyle>
                          <input
                            id="year"
                            type="number"
                            placeholder="Ano"
                            onChange={handleYear}
                            value={yearTrack}
                          />
                        </S.InputStyle>
                      )}
                    </S.RowContainer>
                    {!reviewMode && (
                      <>
                        <S.RowContainer>
                          <S.Rows>Imagem de capa</S.Rows>
                          <S.Rows>
                            <form>
                              <label htmlFor="cover">
                                <input
                                  id="cover"
                                  type="file"
                                  accept="image/*"
                                  onChange={(event) => handleImageTrack(event)}
                                />
                                <S.CustomInputFile>
                                  <S.FileName>{fileName}</S.FileName>
                                  <S.ButtonSelectFile type="button">Selecionar</S.ButtonSelectFile>
                                </S.CustomInputFile>
                              </label>
                            </form>
                          </S.Rows>
                        </S.RowContainer>
                        <S.RowContainer>
                          <S.Rows>Arquivos</S.Rows>
                          <S.Rows>
                            <S.FileText>
                              Insira o PDF da prova, gabarito e outros arquivos e imagens auxiliares
                            </S.FileText>
                          </S.Rows>
                          <S.Rows>
                            <label htmlFor="pdf">
                              <input
                                id="pdf"
                                type="file"
                                onChange={(event) => handleFiles(event)}
                              />
                              <S.CustomInputFile>
                                <S.FileName>{pdfFileName}</S.FileName>
                                <S.ButtonSelectFile type="button">Selecionar</S.ButtonSelectFile>
                              </S.CustomInputFile>
                            </label>
                          </S.Rows>
                          <S.LastFileText>
                            Arquivos não serão publicados, apenas estarão disponíveis para operação
                          </S.LastFileText>
                        </S.RowContainer>
                      </>
                    )}
                  </S.StepContainer>
                  {!reviewMode && (
                    <>
                      <S.StepContainer>
                        <S.Step>
                          <S.StepOder>2</S.StepOder>
                          <S.StepText>Disponibilidade</S.StepText>
                        </S.Step>
                        <S.RowContainer>
                          <S.Rows>Início e prazo final do Simulado</S.Rows>
                          <S.RowContainerInputs>
                            <DatePicker
                              label="Início"
                              time={true}
                              value={startTime}
                              handleChange={(event) => setStartTime(event)}
                            />
                          </S.RowContainerInputs>
                          <S.RowContainerInputs>
                            <DatePicker
                              label="Fim"
                              time={true}
                              value={endTime}
                              handleChange={(event) => setEndTime(event)}
                            />
                          </S.RowContainerInputs>
                        </S.RowContainer>
                      </S.StepContainer>
                      <S.StepContainer>
                        <S.Step>
                          <S.StepOder>3</S.StepOder>
                          <S.StepText>Nota de corte</S.StepText>
                        </S.Step>
                        <S.RowContainerInputs>
                          <S.ContainerInstitution>
                            <SelectWithPagination
                              onChange={setSelectedInstitution}
                              loadOptions={loadInstitutions}
                              value={selectedInstitution}
                              name="institution"
                              backgroundColor="#f0f2f5"
                              height={48}
                              placeholder="Instituição"
                            />
                          </S.ContainerInstitution>
                        </S.RowContainerInputs>
                        <S.RowContainerInputs>
                          <S.InputStyle>
                            <input
                              id="yearScore"
                              type="number"
                              placeholder="Ano"
                              value={yearScore}
                              onChange={handleYearScore}
                            />
                          </S.InputStyle>
                        </S.RowContainerInputs>
                      </S.StepContainer>

                      <S.StepContainer>
                        <S.Step>
                          <S.StepOder>4</S.StepOder>
                          <S.StepText>Configurações de questões</S.StepText>
                        </S.Step>
                        <S.RowContainer>
                          <S.Rows>Adicionar questões novas</S.Rows>
                          <SelectionQuestions
                            allowDrag={false}
                            hiddenRemoveIcon={false}
                            setQuestionsSelected={setQuestionsSelected}
                            setNumberQuestions={(value) => {
                              setNumberQuestions(value);
                            }}
                          />
                        </S.RowContainer>
                        <S.RowContainer>
                          <S.Rows styles={{ width: 70 }}>Alternativas (número de respostas)</S.Rows>
                          <S.ContainerAlternatives>
                            <Select
                              name="number-of-options"
                              placeholder="Selecione"
                              onChange={(option) => setNumberOptions(option)}
                              options={[
                                { label: 'A-B', value: 2, key: 'm' },
                                { label: 'A-B-C-D', value: 4, key: 't' },
                                { label: 'A-B-C-D-E', value: 5, key: 'x' },
                              ]}
                              styles={SelectStyle}
                              isDisabled={trackType === 'd'}
                            />
                          </S.ContainerAlternatives>
                        </S.RowContainer>

                        <S.RowContainerBorder>
                          <S.Header>
                            Adicionar questões existentes
                            <S.SearchButton
                              onClick={() => {
                                setShowQuestionsModal(true);
                                Mixpanel.track("Clicou 'Criação de simulado - busca de questões'");
                              }}
                            >
                              Buscar questões
                            </S.SearchButton>
                            <S.ImportButton onClick={handleImportButton}>
                              Importar planilha
                            </S.ImportButton>
                          </S.Header>
                          {loadingQuestions && questionsCsvInfo.currentPage === 1 && (
                            <S.NoQuestionsDiv>
                              <LoadingSpinner />
                            </S.NoQuestionsDiv>
                          )}
                          {allQuestions.length === 0 && !loadingAllQuestions ? (
                            <S.NoQuestionsDiv>
                              <S.RowsCenter>Nenhuma questão existente adicionada</S.RowsCenter>
                              <S.RowMinor>
                                Utilize a busca ou adicione questões com uma planilha
                              </S.RowMinor>
                            </S.NoQuestionsDiv>
                          ) : (
                            <>
                              {allQuestions.map((question) => (
                                <div key={question.id}>
                                  <S.QuestionContainer>
                                    <S.QuestionHeader>
                                      <S.QuestionInfoContent>
                                        <S.QuestionNumber>
                                          {question.question_number}
                                        </S.QuestionNumber>
                                        <img src={DragIco} alt="dragicon" />
                                        <S.QuestionContent>
                                          {question.content || 'Sem enunciado'}
                                        </S.QuestionContent>
                                      </S.QuestionInfoContent>
                                      <S.QuestionButtonsContent>
                                        <S.QuestionButton
                                          onClick={() => {
                                            window.open(
                                              `${process.env.REACT_APP_URL}/question/${question.id}/content `,
                                              '_blank',
                                            );
                                          }}
                                        >
                                          <img src={LinkLogo} alt="See question" /> Visualizar
                                        </S.QuestionButton>
                                        <S.QuestionButton
                                          onClick={() => handleRemoveExistingQuestion(question)}
                                          disabled={loadingAllQuestions}
                                        >
                                          <RemoveLogo /> Remover
                                        </S.QuestionButton>
                                      </S.QuestionButtonsContent>
                                    </S.QuestionHeader>
                                  </S.QuestionContainer>
                                </div>
                              ))}
                              {loadingQuestions && <Loader />}
                            </>
                          )}
                        </S.RowContainerBorder>
                      </S.StepContainer>
                    </>
                  )}
                  {(reviewMode || challenge) && (
                    <S.StepContainer>
                      <S.Step>
                        <S.StepOder>{reviewMode ? 2 : 5}</S.StepOder>
                        <S.StepText>Questões no simulado</S.StepText>
                      </S.Step>
                      <S.RowContainer>
                        <ListSpecialitiesAndQuestions
                          setQuestions={setQuestionsChallenge}
                          questions={questionsChallenge}
                          canAction={reviewMode}
                        />
                      </S.RowContainer>
                    </S.StepContainer>
                  )}{' '}
                  <S.StepContainer>
                    <S.Step>
                      <S.StepOder>{reviewMode ? 3 : challenge ? 6 : 5}</S.StepOder>
                      <S.StepText>Atribuir a cursos</S.StepText>
                    </S.Step>
                    <S.RowContainer>
                      <S.Rows>Selecione os cursos para vincular</S.Rows>
                      <S.Rows>
                        <MultiselectAutocomplete
                          selectedOptions={selectedCourses}
                          setSelectedOptions={handleSelectedOptions}
                          label="Cursos"
                          placeholder="Selecione"
                          options={courses}
                          fetchSuggestions={filteredCourses}
                          loading={loadingCourses}
                          disabled={loadingCourses}
                        />
                      </S.Rows>
                    </S.RowContainer>
                  </S.StepContainer>
                </S.FormContainer>
              </S.ContainerNewTrack>
            </S.Container>

            {!showQuestionsModal && (
              <S.FooterTrack>
                <S.ButtonBack type="button" onClick={() => history.push('/challenge')}>
                  <MdArrowBack size={18} color="#01c3aa" />
                  Voltar
                </S.ButtonBack>{' '}
                <S.ButtonSave
                  type="submit"
                  disabled={disabled || loadingAllQuestions}
                  onClick={() => (reviewMode ? updateQuestionsOrder() : validationForm())}
                >
                  {reviewMode ? 'Salvar e ir para a edição' : 'Salvar e revisar'}
                  <MdArrowForward
                    size={18}
                    color={disabled || loadingAllQuestions ? '#d1d7e1' : '#fff'}
                  />
                </S.ButtonSave>
              </S.FooterTrack>
            )}
          </S.Wrap>
          {showQuestionsModal && (
            <SearchQuestionsModal show={showQuestionsModal} close={handleCloseQuestionsModal} />
          )}
        </>
      )}
    </>
  );
}
export default ConfigChallenge;
