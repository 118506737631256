import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import { toast } from 'react-toastify';
import HeaderComponent from '~/components/HeaderComponent';
import MultiselectAutocomplete from '~/components/MultiselectAutocomplete';
import ListAndOrderParent from '~/components/ListAndOrderParent';
import ModalConfirmation from '~/components/ModalConfirmation';
import Loader from '~/components/Loader';
import DatePicker from '~/components/DatePickerComponent';
import AddClassObjects from '../components/AddClassObjects';
import Forms from '../components/Forms';
import { INPUTS_INITIAL_STATE, YesOrNoRadio, TRACK_TYPES, CONTENT_TYPES } from '../mocks/mock';
import { CourseAPICMS, CourseLessonTemplateAPICMS } from '~/services/apiCallsCMS';
import { useFeatures } from '~/hooks-querys/feature';
import { useResidencyDegree } from '~/hooks-querys/residencydegree';
import { useGetCourses } from '~/hooks-querys/course';
import history from '~/services/history';
import { saveCreatedCourseName } from '~/store/modules/courses/actions';
import { convertStringToBoolean } from '~/utils/util';
import {
  transformOptions,
  handleChangeFeatures,
  handleChangeTrackTypes,
  filterFeatures,
  confirmRemove,
  handleBack,
  RenderRadioGroup,
  handleChangeData,
  RenderInput,
  sendMixpanelTracks,
  hasHttpOrHttps,
} from '../utils/utils';

import * as S from './styles';

function ConfigCourse() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [formsData, setFormsData] = useState(INPUTS_INITIAL_STATE);
  const [residencyDegree, setResidencyDegree] = useState([]);
  const [features, setFeatures] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedTrackTypes, setSelectedTrackTypes] = useState([]);
  const [contentType, setContentType] = useState([]);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [idDelete, setIdDelete] = useState('');
  const [objectsId, setObjectsId] = useState(0);
  const [loadingSave, setLoadingSave] = useState(false);
  const { data: featuresData, isLoading: loadingFeatures } = useFeatures({ no_page: 1 });
  const { data: residencyDegreeData, isLoading: loadingResidencyDegree } = useResidencyDegree({
    no_page: true,
    ordering: 'abbreviation',
  });
  const { data: coursesData, isLoading: loadingCoursesData } = useGetCourses(id, {
    config: {
      enabled: !!id,
    },
  });

  const handleRemove = () => {
    const source = Axios.CancelToken.source();
    setShowModalConfirmation(false);

    const updatedContentTypes = contentType.filter((content) => {
      return content.id !== idDelete;
    });

    CourseLessonTemplateAPICMS.deleteSingleObject(idDelete, { course_id: id }, source.token)
      .then(() => {
        setContentType(updatedContentTypes);
        setObjectsId(objectsId - 1);
        toast.success('Objeto de aula deletado');
      })
      .catch(() => toast.error('Erro ao deletar objeto de aula. Tente novamente mais tarde'));
  };

  const handleAdd = async (content_type) => {
    const newItemName = CONTENT_TYPES.filter((content) => {
      return content.content_type === content_type;
    });

    const newItem = {
      id: objectsId,
      name: newItemName[0].label,
      object_type: newItemName[0].object_type,
      buttons: [
        {
          hasBorder: false,
          text: 'remover',
          icon: 'delete',
          action: () => confirmRemove(objectsId, setShowModalConfirmation, setIdDelete),
        },
      ],
    };

    await CourseLessonTemplateAPICMS.create({
      course_id: id,
      order: objectsId,
      object_type: newItemName[0].object_type,
    }).then(() => {
      setObjectsId(objectsId + 1);
      setContentType((contentType) => [...contentType, newItem]);
    });
  };

  const submitForm = async () => {
    if (loadingSave) return;

    setLoadingSave(true);
    const source = Axios.CancelToken.source();
    if (!formsData.name) {
      return toast.error('O nome é obrigatório para a criação do curso.');
    }

    if (
      (formsData.certificate_minimum_percentage_required &&
        formsData.certificate_minimum_percentage_required <= 0) ||
      formsData.certificate_minimum_percentage_required > 100
    ) {
      return toast.error('A porcentagem deve ser entre 1 e 100');
    }

    const subjectDate = new Date(formsData.subject_start_availability);
    const lessonDate = new Date(formsData.lesson_start_availability);
    let dateData;
    let modifiedUrlCheckout;

    if (formsData.subject_start_availability) {
      dateData = {
        subject_start_availability: `${subjectDate.getFullYear()}-${
          subjectDate.getMonth() + 1
        }-${subjectDate.getDate()}`,
      };
    }

    if (formsData.lesson_start_availability) {
      dateData = {
        ...dateData,
        lesson_start_availability: `${lessonDate.getFullYear()}-${
          lessonDate.getMonth() + 1
        }-${lessonDate.getDate()}`,
      };
    }

    if (formsData.url_checkout) {
      modifiedUrlCheckout = hasHttpOrHttps(formsData.url_checkout)
        ? formsData.url_checkout
        : `https://${formsData.url_checkout}`;
    }

    const transformedFormsData = {
      ...formsData,
      certificate_minimum_percentage_required: parseInt(
        formsData.certificate_minimum_percentage_required,
      ),
      mentoring_access: convertStringToBoolean(formsData.mentoring_access),
      track_available: convertStringToBoolean(formsData.track_available),
      residency_tree: convertStringToBoolean(formsData.residency_tree),
      subject_available: convertStringToBoolean(formsData.subject_available),
      lesson_available: convertStringToBoolean(formsData.lesson_available),
      url_checkout: modifiedUrlCheckout,
      ...dateData,
    };

    sendMixpanelTracks(
      "Clicou 'Configuração de cursos - Botão de Salvar'",
      transformedFormsData,
      features,
      selectedTrackTypes,
      residencyDegree,
      contentType,
    );

    const payload = { ids: contentType.map((item) => item.id) };
    if (payload.ids.length > 0) {
      await CourseLessonTemplateAPICMS.updateOrder(payload, source.token);
    }

    await CourseAPICMS.update(id, transformedFormsData).then((response) => {
      dispatch(saveCreatedCourseName(response.data.name));
      history.push('/courses');
      setLoadingSave(false);
    });
  };

  useEffect(() => {
    if (!coursesData || loadingCoursesData) return;

    setFormsData({
      name: coursesData.name,
      mentoring_access: coursesData?.mentoring_access?.toString(),
      certificate_minimum_percentage_required: coursesData.certificate_minimum_percentage_required,
      course_features: coursesData.course_features,
      residency_degree: coursesData?.residency_degree && coursesData?.residency_degree.toString(),
      url_checkout: coursesData?.url_checkout,
      track_available: coursesData.track_available?.toString(),
      residency_tree: coursesData.residency_tree?.toString(),
      track_type_available: coursesData.track_type_available,
      subject_available: coursesData.subject_available?.toString(),
      subject_start_availability: coursesData.subject_start_availability,
      lesson_available: coursesData.lesson_available?.toString(),
      lesson_start_availability: coursesData.lesson_start_availability,
    });

    if (coursesData.track_type_available && coursesData.track_type_available.length > 0) {
      const courseSelectedTrackTypes = TRACK_TYPES.filter((trackType) => {
        return coursesData.track_type_available.includes(trackType.value);
      });

      setSelectedTrackTypes(courseSelectedTrackTypes);
    }

    if (coursesData.lesson_template) {
      setContentType(
        coursesData.lesson_template.map((content) => {
          const selectedObject = CONTENT_TYPES.find(
            (element) => element.object_type === content.object_type,
          );

          return {
            id: content.id,
            name: selectedObject?.label,
            object_type: content.object_type,
            buttons: [
              {
                hasBorder: false,
                text: 'Remover',
                icon: 'delete',
                action: () => confirmRemove(content.id, setShowModalConfirmation, setIdDelete),
              },
            ],
          };
        }),
      );
      setObjectsId(coursesData.lesson_template.length + 1);
    }
  }, [coursesData]);

  useEffect(() => {
    if (!featuresData || loadingFeatures) return;
    return setFeatures(featuresData.map(transformOptions));
  }, [featuresData]);

  useEffect(() => {
    if (!residencyDegreeData || loadingResidencyDegree) return;
    return setResidencyDegree(residencyDegreeData.map(transformOptions));
  }, [residencyDegreeData]);

  useEffect(() => {
    if (features.length <= 0 || !coursesData) return;

    if (coursesData.course_features && coursesData.course_features.length > 0) {
      const courseSelectedFeatures = features.filter((feature) => {
        return coursesData.course_features.includes(feature.key);
      });

      setSelectedFeatures(courseSelectedFeatures);
    }
  }, [features, coursesData]);

  const RenderDatePicker = (prop) => {
    return (
      <DatePicker
        label="Início"
        dateFormat="dd/MM/yyyy"
        value={formsData[prop] || null}
        handleChange={(event) => handleChangeData(event, prop, setFormsData)}
        setFormsData={setFormsData}
      />
    );
  };

  const content = [
    [
      {
        title: '',
        subtitle: '',
        shortPadding: true,
        borderBottom: false,
        items: [
          {
            subtitle: 'Nome',
            items: [RenderInput('Nome do curso', 'name', true, false, formsData, setFormsData)],
          },
          {
            subtitle: 'Acesso ao planner',
            fullWidth: true,
            items: [RenderRadioGroup(YesOrNoRadio, 'mentoring_access', formsData, setFormsData)],
          },
          {
            subtitle: 'Porcentagem para certificado',
            items: [
              RenderInput(
                'Porcentagem',
                'certificate_minimum_percentage_required',
                false,
                true,
                formsData,
                setFormsData,
              ),
            ],
          },
          {
            subtitle: 'Features do curso',
            items: [
              <MultiselectAutocomplete
                selectedOptions={selectedFeatures}
                setSelectedOptions={(options) =>
                  handleChangeFeatures(options, setSelectedFeatures, setFormsData)
                }
                label={loadingFeatures ? 'Carregando...' : 'Features'}
                placeholder="Selecione"
                options={features}
                fetchSuggestions={filterFeatures}
                loading={loadingFeatures}
                disabled={loadingFeatures}
                multiSelectLimit={features.length}
              />,
            ],
          },
          {
            subtitle: 'Nível de Residência',
            fullWidth: true,
            items: [RenderRadioGroup(residencyDegree, 'residency_degree', formsData, setFormsData)],
          },
          {
            subtitle: 'Url checkout',
            items: [
              RenderInput('Url checkout', 'url_checkout', true, false, formsData, setFormsData),
            ],
          },
        ],
      },
    ],
    [
      {
        title: 'Trilhas',
        subtitle:
          'Configuração para tipos de trilha: Pessoal, Simulado, Exame (Prova), Capítulo (Trilha-Pré e Trilha-Pós), Revisão e Trilha Inteligente',
        shortPadding: false,
        borderBottom: true,
        items: [
          {
            subtitle: 'Disponibilizar trilhas',
            items: [
              RenderRadioGroup(YesOrNoRadio, 'track_available'),
              <MultiselectAutocomplete
                selectedOptions={selectedTrackTypes}
                setSelectedOptions={(options) =>
                  handleChangeTrackTypes(options, setSelectedTrackTypes, setFormsData)
                }
                label={loadingFeatures ? 'Carregando...' : 'Tipos'}
                placeholder="Selecione"
                options={TRACK_TYPES}
                fetchSuggestions={filterFeatures}
                multiSelectLimit={TRACK_TYPES.length}
              />,
            ],
          },
          {
            subtitle: 'Disponibilizar questões R3',
            fullWidth: true,
            items: [RenderRadioGroup(YesOrNoRadio, 'residency_tree', formsData, setFormsData)],
          },
        ],
      },
      {
        title: 'Apostilas',
        subtitle: '',
        borderBottom: true,
        items: [
          {
            subtitle: 'Disponibilizar apostilas',
            fullWidth: true,
            items: [RenderRadioGroup(YesOrNoRadio, 'subject_available', formsData, setFormsData)],
          },
          {
            subtitle: 'Início da disponibilidade',
            fullWidth: true,
            items: [RenderDatePicker('subject_start_availability')],
          },
        ],
      },
      {
        title: 'Aulas',
        subtitle: '',
        borderBottom: false,
        items: [
          {
            subtitle: 'Disponibilizar aulas',
            fullWidth: true,
            items: [RenderRadioGroup(YesOrNoRadio, 'lesson_available', formsData, setFormsData)],
          },
          {
            subtitle: 'Início da disponibilidade',
            fullWidth: true,
            items: [RenderDatePicker('lesson_start_availability')],
          },
        ],
      },
    ],
    [
      {
        title: '',
        subtitle: '',
        shortPadding: true,
        borderBottom: false,
        items: [
          {
            subtitle: '',
            fullWidth: true,
            items: [
              <ListAndOrderParent
                parentData={contentType}
                setParentData={setContentType}
                parentAllowDrag={true}
              />,
            ],
          },
          {
            subtitle: '',
            fullWidth: true,
            items: [<AddClassObjects buttonsData={CONTENT_TYPES} onAdd={handleAdd} />],
          },
        ],
      },
    ],
  ];

  return (
    <>
      {loadingCoursesData || loadingSave ? (
        <Loader />
      ) : (
        <>
          <HeaderComponent title={'Cursos'} />
          <S.Wrap>
            <S.Content>
              <Forms content={content} />
            </S.Content>
          </S.Wrap>
          <S.Footer>
            <S.BackButton onClick={handleBack}>
              <S.StyledArrowLeft />
              Voltar
            </S.BackButton>
            <S.SaveButton onClick={submitForm}>
              Salvar <S.StyledArrowRight />
            </S.SaveButton>
          </S.Footer>
          {showModalConfirmation && (
            <ModalConfirmation
              title="Excluir objeto de aula"
              message="Você deseja excluir esse objeto de aula?"
              actionText="Excluir"
              showModal={showModalConfirmation}
              handleClose={() => setShowModalConfirmation(false)}
              handleAction={handleRemove}
            />
          )}
        </>
      )}
    </>
  );
}

export default ConfigCourse;
