import React, { useState } from 'react';

import { Button } from '@medway-npm/ds-medstar';
import CloudLogo from '~/assets/drops-cloud-logo.svg';
import PlusLogo from '~/assets/drops-plus-logo.svg';
import DropsFile from '~/assets/drops-fileicon-logo.svg';

import * as S from './styles';

function ImageUploader({ imageFile, setImageFile }) {
  const [imageFallback, setImageFallback] = useState(null);

  function handleImageError() {
    setImageFallback(DropsFile);
  }

  function removeImage() {
    setImageFile(null);
    setImageFallback(null);
  }

  return (
    <>
      <S.MainContainer>
        {!imageFile && (
          <label for="fileImage">
            <S.IconContainer>
              <img src={CloudLogo} alt="Cloud logo" />
              <S.AbsoluteIcon>
                <img src={PlusLogo} alt="Plus logo" />
              </S.AbsoluteIcon>
            </S.IconContainer>
            <S.SelectImageText>Clique e selecione um arquivo</S.SelectImageText>
            <S.InputHide>
              <input
                id="fileImage"
                type="file"
                name="myImage"
                accept="image/png,image/jpe,image/jpeg,image/jpg,.pdf"
                style={{ display: 'none' }}
                onChange={(event) => {
                  setImageFile(event.target.files[0]);
                }}
              />
            </S.InputHide>
          </label>
        )}
        {imageFile && (
          <S.SelectedImageContainer>
            <S.SelectedImage>
              <S.SelectImageText>Imagem selecionada:</S.SelectImageText>
              <S.ImageAndNameContainer>
                <img
                  alt={imageFile?.name}
                  src={imageFallback || URL.createObjectURL(imageFile)}
                  onError={handleImageError}
                />
                <p> {imageFile.name} </p>
              </S.ImageAndNameContainer>
            </S.SelectedImage>
            <Button variant="secondary" size="medium" onClick={removeImage}>
              Remover imagem
            </Button>
          </S.SelectedImageContainer>
        )}
      </S.MainContainer>
    </>
  );
}

export default ImageUploader;
