import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 110;
const drawerScheduleWidth = 368;

export const Container = styled.div`
  width: ${(props) => (props.removeSidebar ? '100%' : 'calc(100% - 80px)')};
  max-width: 100%;
  min-height: calc(100vh - 140px);
  margin-inline: 0;
  margin-left: ${(props) => (props.removeSidebar ? '0' : '80px')};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  bottom: 0;
  width: 100%;
  height: 30px;
  max-height: 30px;
  background-color: #f5f5fb;
  color: #b4b4b5;
  font-size: 12px;
`;

export const SelectStyle = {
  control: (styles) => ({
    ...styles,
    backgroundColor: '#F0F2F5',
    width: '100%',
    color: '#3D5582',
    fontSize: 16,
    border: '2px solid #E5E8EE',
    borderRadius: 8,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#FFF',
      border: '3px solid #3D5582',
      fontWeight: 600,
      boxShadow: '0px 0px 0px 6px rgba(61, 85, 130, 0.2)',
    },
    ':focus-within': {
      backgroundColor: '#FFF',
      border: '3px solid #01cfb5',
      fontWeight: 600,
      boxShadow: '0px 0px 0px 6px rgba(1, 207, 181, 0.2)',
    },
  }),
  option: (styles) => {
    return {
      ...styles,
      backgroundColor: '#fff',
      color: '#3D5582',
      fontSize: 16,
      fontWeight: 400,
      zIndex: 999,
      cursor: 'pointer',
    };
  },
  menu: (styles) => ({
    ...styles,
    marginTop: 8,
    marginBottom: 8,
    zIndex: 999,
  }),
  input: (styles) => ({
    ...styles,
    marginTop: 8,
    marginBottom: 8,
  }),

  dropdownIndicator: (styles) => ({
    ...styles,
    padding: 0,
    transition: 'color 150ms',
    border: '2px solid #3D5582',
    borderRadius: 99,
    color: '#3D5582',
    alignItems: 'center',
    maxHeight: 22,
    maxWidth: 22,
    ':hover': {
      border: '2px solid #01cfb5',
      color: '#01cfb5',
    },
    ':active': {
      border: '2px solid #01cfb5',
      color: '#01cfb5',
    },
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    paddingBottom: '5px',
    color: '#3D5582',
    position: 'relative',
    right: '5px',
    height: 48,
  }),
  indicatorSeparator: () => ({
    backgroundColor: '#eee',
  }),
  singleValue: () => ({
    color: '#3D5582',
    marginBottom: 8,
    fontSize: 16,
    fontWeight: 400,
    border: 0,
  }),
  placeholder: (styles) => ({
    ...styles,
    paddingBottom: '8px',
    color: '#3D5582',
    fontWeight: 400,
    fontSize: 16,
    border: 0,
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
  }),
};

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100vw - 1%)',
  },
  toolbar: {
    paddingRight: 40, // keep right padding when drawer closed
    backgroundColor: '#fff',
    height: 64,
    width: 'calc(100vw - 1%)',
  },
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuSecheduleOpen: {
    marginLeft: drawerScheduleWidth,
  },
  menuSecheduleClose: {
    marginLeft: 0,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    color: 'rgb(59, 63, 182)',
    fontSize: 10,
    fontWeight: 600,
  },
  linkTitle: {
    cursor: 'pointer',
    margin: 0,
  },
  drawerPaper: {
    position: 'fixed',
    whiteSpace: 'nowrap',
    backgroundColor: '#00205b',
    width: 220,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    zIndex: 1100,
  },
  drawerPaperSchedule: {
    position: 'fixed',
    whiteSpace: 'nowrap',
    width: drawerScheduleWidth,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    zIndex: 1100,
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    zIndex: 1100,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(10),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentWithSchedule: {
    marginLeft: drawerScheduleWidth + drawerWidth / 2,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  container: {
    minHeight: '85vh',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export const MainContainer = styled.div`
  display: flex;
`;
