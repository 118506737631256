import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getMonth, getYear, isAfter } from 'date-fns';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NewEmptyContent from '~/components/NewEmptyContent';
import Checkbox from '~/components/Checkbox';
import Loader from '~/components/Loader';
import TitleCheckbox from '../TitleCheckbox';
import SelectAllCheckbox from '../SelectAllCheckbox';
import { ReactComponent as ExternalLinkComponent } from '~/assets/external-link.svg';
import { STATUS_OPTIONS } from '../../variables';
import { loadMonths } from '../FilterDropdown/utils';
import MultiplierDropdown from '../MultiplierDropdown';
import { formatPrice, objectIsEmpty, useWindowSize } from '~/utils/util';
import { modifyNumberToFormat } from '../../utils';
import * as S from './styles';
import { StatusTag } from '../FilterDropdown/styles';

import {
  TableContainer,
  TableContent,
  TableItem,
  ItemsContainer,
  LeftHeader,
  RightHeader,
  ActivitiesCount,
} from '~/pages/Activities/components/TaskList/styles';

function ListActivities({
  activitiesData,
  handleOpenChat,
  handleOpenOffcanvas,
  selectedIds,
  setSelectedIds,
  managePage,
  selectedStatus,
  handleChangeMultiplier,
  helperChecked,
  checkAll,
  childrenChecked,
  checkChild,
  setWaitingRequestFromActivity,
  setChildrenActivities,
  fetchedTasks,
  fetchingTasksData,
  getTaskData,
  expandedSummary,
  setExpandedSummary,
}) {
  const user = useSelector((state) => state.auth.user);
  const { width } = useWindowSize();
  const [titleWidth, setTitleWidth] = useState(null);
  const [checkAllData, setCheckAllData] = useState(null);

  const rightItemsRef = useRef(null);
  const gridRef = useRef(null);

  const showCheckboxes = !managePage && selectedStatus === 'a';
  const showMultiplier = managePage && (selectedStatus === 'c' || selectedStatus === 'r');
  const showBulkActions = managePage && selectedStatus === 'c';

  const ReturnStatusTag = (tagStatus) => {
    const tag = STATUS_OPTIONS.find((status) => status.value === tagStatus);

    return (
      <StatusTag color={tag.color} background={tag.bgColor}>
        {tag.icon}
        {tag.label}
      </StatusTag>
    );
  };

  const preventCollapse = (event) => {
    event.stopPropagation();
  };

  const summaryClick = (id, month, year) => {
    const identifier = `${id}-${month}-${year}`;

    if (expandedSummary === identifier) {
      return setExpandedSummary('');
    } else {
      setExpandedSummary(identifier);
    }

    getTaskData(id, month, year);
  };

  const returnTitle = (inputMonth, inputYear) => {
    const monthName = loadMonths().find((month) => month.value === inputMonth);
    if (!monthName) {
      return inputYear;
    }
    return `${monthName?.label} ${inputYear}`;
  };

  const returnFormattedPrice = (inputPrice) => {
    if (!inputPrice) return '-';
    const inputValue = modifyNumberToFormat(inputPrice);

    return formatPrice(inputValue);
  };

  const countAllActivities = (months) => {
    if (!months || months?.length === 0) return;

    let count = 0;
    months.map((month) => {
      return (count = count + month?.task_count);
    });

    return `${count} ${count > 1 ? 'atividades' : 'atividade'}`;
  };

  const returnMultiplier = (multiplier) => {
    if (!multiplier) return '-';
    return `${multiplier}x`;
  };

  const fetchAndCheckAll = (event, activity, responsible_id) => {
    const identifier = `${responsible_id}-${activity?.month}-${activity?.year}`;

    if (!expandedSummary === identifier && event) {
      setExpandedSummary(identifier);
    }

    if (fetchedTasks[identifier]) {
      if (!event) {
        setSelectedIds({
          ...selectedIds,
          [identifier]: [],
        });
      } else {
        setSelectedIds({
          ...selectedIds,
          [identifier]: fetchedTasks[identifier].map((task) => task.id),
        });
      }

      checkAll(event, identifier, fetchedTasks[identifier]);
    }

    getTaskData(responsible_id, activity?.month, activity?.year);
    setCheckAllData({
      event: event,
      name: identifier,
      responsible_id: responsible_id,
      month: activity?.month,
      year: activity?.year,
    });
  };

  const getDates = (currentDate, activityDate) => {
    const currentMonth = getMonth(currentDate) + 1;
    const currentYear = getYear(currentDate);
    const activityMonth = getMonth(activityDate) + 1;
    const activityYear = getYear(activityDate);

    return {
      currentMonth: currentMonth,
      currentYear: currentYear,
      activityMonth: activityMonth,
      activityYear: activityYear,
    };
  };

  const disableList = (ended_at) => {
    if (managePage) return false;
    const date = new Date();
    const taskDate = new Date(ended_at);
    const dates = getDates(date, taskDate);
    if (dates.currentMonth === dates.activityMonth && dates.currentYear === dates.activityYear)
      return true;
    return isAfter(taskDate, date);
  };

  const disableListParent = (activity) => {
    if (managePage || activity?.length === 0) return false;
    const date = new Date();

    if (activity?.month) {
      const taskDate = new Date(Date.UTC(activity?.year, activity?.month, 0));

      const dates = getDates(date, taskDate);
      if (dates.currentMonth === dates.activityMonth && dates.currentYear === dates.activityYear)
        return true;

      return isAfter(taskDate, date);
    }

    const validation = activity?.map((activity) => {
      const taskDate = new Date(activity?.ended_at);
      const dates = getDates(date, taskDate);
      if (dates.currentMonth === dates.activityMonth && dates.currentYear === dates.activityYear)
        return true;
      return isAfter(taskDate, date);
    });

    return validation.every((value) => value === true);
  };

  const removeDisabledChildren = (activities) => {
    if (!activities || activities.length === 0) return activities;

    return activities?.filter((activity) => !disableList(activity.ended_at));
  };

  const showCheckboxToResponsible = (responsible_id) => {
    if (!responsible_id || !user) return false;
    return user?.id === responsible_id;
  };

  useEffect(() => {
    if (
      titleWidth ||
      (!rightItemsRef && !rightItemsRef?.current) ||
      (!gridRef && !gridRef?.current) ||
      objectIsEmpty(fetchedTasks)
    )
      return;

    const calculatedWidth = gridRef.current?.offsetWidth - rightItemsRef.current?.offsetWidth - 20;
    if (calculatedWidth <= 0) {
      return setTitleWidth(100);
    }
    if (showBulkActions) {
      return setTitleWidth(calculatedWidth - 34);
    }
    return setTitleWidth(calculatedWidth);
  }, [rightItemsRef, gridRef, width, fetchedTasks]);

  useEffect(() => {
    if (fetchedTasks) {
      setChildrenActivities(fetchedTasks);
    }

    if (checkAllData) {
      if (
        disableListParent(
          fetchedTasks[
            `${checkAllData.responsible_id}-${checkAllData.month}-${checkAllData?.year}`
          ],
        )
      ) {
        setCheckAllData(null);
        return;
      }

      const taskIdentifier = `${checkAllData.responsible_id}-${checkAllData.month}-${checkAllData?.year}`;

      checkAll(checkAllData.event, checkAllData.name, fetchedTasks[taskIdentifier]);

      setCheckAllData(null);

      if (!objectIsEmpty(fetchedTasks)) {
        const childrenIds = [];

        Object.keys(fetchedTasks).forEach((key) => {
          return fetchedTasks[key]?.map((task) => {
            return childrenIds.push(task.id);
          });
        });

        setSelectedIds({
          ...selectedIds,
          [`${checkAllData.responsible_id}-${checkAllData.month}-${checkAllData?.year}`]:
            fetchedTasks[taskIdentifier]?.map((task) => task.id),
        });
      }
    }
  }, [fetchedTasks]);

  useEffect(() => {
    setWaitingRequestFromActivity(fetchingTasksData);
  }, [fetchingTasksData]);

  const renderList = (activity, responsible_id) => {
    return (
      <S.Container>
        <S.AccordionStyle>
          <Accordion
            defaultExpanded={false}
            expanded={expandedSummary === `${responsible_id}-${activity?.month}-${activity?.year}`}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={() => summaryClick(responsible_id, activity?.month, activity?.year)}
            >
              <S.Header>
                <LeftHeader>
                  {showCheckboxes || showCheckboxToResponsible(responsible_id) ? (
                    <S.CheckboxContainer onClick={preventCollapse}>
                      <SelectAllCheckbox
                        id={`${responsible_id}-${activity.month}-${activity.year}`}
                        title={returnTitle(activity.month, activity.year)}
                        selectedItems={selectedIds}
                        setSelectedItems={setSelectedIds}
                        clickTitleCheckbox={fetchAndCheckAll}
                        activity={activity}
                        responsible_id={responsible_id}
                        activities={removeDisabledChildren(
                          fetchedTasks[`${responsible_id}-${activity?.month}-${activity?.year}`] ||
                            [],
                        )}
                        checked={helperChecked.includes(
                          `${responsible_id}-${activity?.month}-${activity?.year}`,
                        )}
                        disabled={disableListParent(
                          fetchedTasks[`${responsible_id}-${activity?.month}-${activity?.year}`] ||
                            [],
                        )}
                      />
                    </S.CheckboxContainer>
                  ) : showBulkActions ? (
                    <>
                      <S.CheckboxContainer onClick={preventCollapse}>
                        <Checkbox
                          id={`${responsible_id}-${activity.month}-${activity.year}`}
                          label=""
                          checked={helperChecked.includes(
                            `${responsible_id}-${activity?.month}-${activity?.year}`,
                          )}
                          onChange={(event) => fetchAndCheckAll(event, activity, responsible_id)}
                          value={`${responsible_id}-${activity?.month}-${activity?.year}`}
                          styleContainer={{ margin: '5px 0 0 14px', width: '20px' }}
                          styleLabel={{ marginBottom: 0 }}
                          checkboxOutineColor="#ccc"
                        />
                      </S.CheckboxContainer>
                      <S.ActivityTitle>
                        {returnTitle(activity.month, activity.year)}
                      </S.ActivityTitle>
                    </>
                  ) : (
                    <S.ActivityTitle>{returnTitle(activity.month, activity.year)}</S.ActivityTitle>
                  )}

                  <ActivitiesCount>
                    {`${activity?.task_count} ${
                      activity?.task_count > 1 ? 'atividades' : 'atividade'
                    }`}
                  </ActivitiesCount>
                </LeftHeader>
                <RightHeader>
                  <S.HeaderText width="120px">MULTIPLICADOR</S.HeaderText>
                  <S.HeaderText>VALOR</S.HeaderText>
                  <S.HeaderText>QUANTIDADE</S.HeaderText>
                  <S.HeaderText>MULTA</S.HeaderText>
                  <S.HeaderText>TOTAL</S.HeaderText>
                  <S.HeaderText width="160px">PAGAMENTO</S.HeaderText>
                  <S.EmptySpace />
                </RightHeader>
              </S.Header>
            </AccordionSummary>

            <AccordionDetails>
              <TableContainer>
                <TableContent>
                  {fetchingTasksData &&
                  expandedSummary === `${responsible_id}-${activity?.month}-${activity?.year}` ? (
                    <Loader />
                  ) : (
                    <>
                      {fetchedTasks[`${responsible_id}-${activity?.month}-${activity?.year}`]?.map(
                        (item) => {
                          return (
                            <TableItem ref={gridRef}>
                              <ItemsContainer>
                                <S.ItemContainer>
                                  {showCheckboxes || showCheckboxToResponsible(item.responsible) ? (
                                    <TitleCheckbox
                                      parentId={`${responsible_id}-${activity.month}-${activity.year}`}
                                      childrenChecked={childrenChecked}
                                      checkChild={checkChild}
                                      id={item.id}
                                      title={item.name}
                                      checkName={`${responsible_id}-${activity?.month}-${activity?.year}`}
                                      activities={
                                        fetchedTasks[
                                          `${responsible_id}-${activity?.month}-${activity?.year}`
                                        ]
                                      }
                                      identifier={`${responsible_id}-${activity?.month}-${activity?.year}`}
                                      selectedItems={selectedIds}
                                      setSelectedItems={setSelectedIds}
                                      disabled={disableList(item?.ended_at)}
                                    />
                                  ) : showBulkActions ? (
                                    <>
                                      <S.CheckboxContainer onClick={preventCollapse}>
                                        <Checkbox
                                          id={`${responsible_id}-${item.id}`}
                                          label=""
                                          checked={childrenChecked.includes(item.id)}
                                          onChange={(event) =>
                                            checkChild(
                                              event,
                                              item.id,
                                              `${responsible_id}-${activity?.month}-${activity?.year}`,
                                              fetchedTasks[
                                                `${responsible_id}-${activity?.month}-${activity?.year}`
                                              ],
                                              `${responsible_id}-${activity?.month}-${activity?.year}`,
                                            )
                                          }
                                          value={item.id}
                                          styleContainer={{ margin: '5px 0 0 14px', width: '20px' }}
                                          styleLabel={{ marginBottom: 0 }}
                                          checkboxOutineColor="#ccc"
                                        />
                                      </S.CheckboxContainer>
                                      <S.HeaderTitle width={titleWidth}>{item.name}</S.HeaderTitle>
                                    </>
                                  ) : (
                                    <S.HeaderTitle width={titleWidth}>{item.name}</S.HeaderTitle>
                                  )}
                                </S.ItemContainer>
                              </ItemsContainer>
                              <ItemsContainer ref={rightItemsRef}>
                                {showMultiplier ? (
                                  <S.MultiplierDropdownContainer>
                                    <MultiplierDropdown
                                      activityId={item.id}
                                      selectedMultiplier={item?.multiplier}
                                      handleChange={handleChangeMultiplier}
                                    />
                                  </S.MultiplierDropdownContainer>
                                ) : (
                                  <S.ItemText width="120px">
                                    {returnMultiplier(item?.multiplier)}
                                  </S.ItemText>
                                )}

                                <S.ItemText>{returnFormattedPrice(item.task_price)}</S.ItemText>
                                <S.ItemText>{item.amount}</S.ItemText>
                                <S.ItemText errorColor={!!item.values_to_pay?.penalty}>
                                  {returnFormattedPrice(item.values_to_pay?.penalty)}
                                </S.ItemText>
                                <S.ItemText>
                                  {returnFormattedPrice(item.values_to_pay?.total_calculated)}
                                </S.ItemText>
                                <S.ItemText width="160px">
                                  {ReturnStatusTag(item.status)}
                                </S.ItemText>
                                <S.IconContainer
                                  onClick={() => handleOpenChat(item?.id, item?.name)}
                                >
                                  <S.StyledChatIcon has_notes={item?.has_task_notes} />
                                </S.IconContainer>
                                <S.IconContainer
                                  onClick={() => handleOpenOffcanvas(item?.id, item?.name)}
                                >
                                  <ExternalLinkComponent />
                                </S.IconContainer>
                              </ItemsContainer>
                            </TableItem>
                          );
                        },
                      )}
                    </>
                  )}
                </TableContent>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </S.AccordionStyle>
      </S.Container>
    );
  };
  return (
    <>
      {activitiesData && activitiesData.length === 0 ? (
        <NewEmptyContent
          title="Nenhuma atividade encontrada"
          subtitle="Conclua a atividade na tela de parceiros e tente novamente"
        />
      ) : (
        <>
          <S.PartnerWrap>
            {activitiesData?.map((activityParent) => {
              return (
                <>
                  <S.AccordionStyle>
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <S.PartnerNameContainer>
                          <S.PartnerName>{activityParent?.responsible_info?.name}</S.PartnerName>
                          <S.partnerCount>
                            {countAllActivities(activityParent?.months)}
                          </S.partnerCount>
                        </S.PartnerNameContainer>
                      </AccordionSummary>

                      <AccordionDetails>
                        <S.Wrap>
                          {activityParent?.results &&
                            activityParent?.results?.map((activity) => {
                              return renderList(activity, activityParent?.responsible_info?.id);
                            })}
                        </S.Wrap>
                      </AccordionDetails>
                    </Accordion>
                  </S.AccordionStyle>
                </>
              );
            })}
          </S.PartnerWrap>
        </>
      )}
    </>
  );
}

export default ListActivities;
