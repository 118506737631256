import { useQuery } from '@tanstack/react-query';
import { LessonHighlightsAPICMS } from '~/services/apiCallsCMS';

const getLessonHighlights = async (params) => {
  try {
    const response = await LessonHighlightsAPICMS.getLessonHighlights(params);
    if (!response && !response.data) {
      throw new Error('No results found');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default function useGetLessonHighlights(params, options) {
  return useQuery({
    queryKey: ['getLessonHighlights', params],
    queryFn: () => getLessonHighlights(params),
    staleTime: 0,
    cacheTime: 0,
    ...options?.config,
  });
}
