import React from 'react';

import * as S from './styles';

import SearchIcon from '~/assets/drops-search.svg';

function Filter({ filter, setFilter }) {
  const changeFilter = (value) => {
    setFilter({ ...filter, search: value, page: 1 });
  };

  const selectValueStyle = {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const selectedProps = {
    width: '100%',
    controlStyle: { marginLeft: 0 },
    singleValueStyle: selectValueStyle,
    placeholderStyle: selectValueStyle,
    valueContainerStyle: { flexWrap: 'nowrap' },
    containerStyle: { minWidth: '140px' },
  };

  return (
    <>
      <S.FilterInput>
        <img src={SearchIcon} alt="Search icon" />
        <input
          type="text"
          value={filter.id}
          onChange={(e) => changeFilter(e.target.value)}
          placeholder="Pesquisar por ID ou nome"
        />
      </S.FilterInput>
    </>
  );
}

export default Filter;
