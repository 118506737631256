import styled from 'styled-components';
const mobileWidth = 450;

const FlexColumnCentralized = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MainContainer = styled(FlexColumnCentralized)`
  box-sizing: border-box;
  gap: 16px;
  background: #ffffff;
  border: 2px dashed #e5e8ee;
  border-radius: 8px;

  :hover {
    box-shadow: 0px 0px 0px 6px rgba(61, 85, 130, 0.2);
    border-radius: 8px;
    border: 2px dashed #3d5582;
  }
  label {
    width: 100%;
    padding: 52px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
`;

export const IconContainer = styled(FlexColumnCentralized)`
  position: relative;
  width: 64px;
  height: 64px;
  background: rgb(240, 242, 245);
  border-radius: 1000px;
`;

export const AbsoluteIcon = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 18px;
  height: 18px;
  background: #00205b;
  border-radius: 1000px;
`;

export const InputHide = styled.div`
  display: none;
`;

export const SelectImageText = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #00205b;

  @media (max-width: ${mobileWidth}px) {
    font-size: 14px;
  }
`;

export const ImageAndNameContainer = styled(FlexColumnCentralized)`
  gap: 6px;
  border: 2px solid #e5e8ee;
  padding: 8px;
  border-radius: 4px;

  img {
    max-width: 70vw;
    width: 100%;
  }

  p {
    word-break: break-all;
  }
`;

export const SelectedImageContainer = styled(FlexColumnCentralized)`
  padding: 52px 0;
  gap: 24px;
  margin: 5px;
  max-width: 250px;

  @media (max-width: ${mobileWidth}px) {
    padding: 24px 0;
    gap: 16px;
  }
`;

export const SelectedImage = styled(FlexColumnCentralized)`
  gap: 16px;
`;
