import { Student } from '~/services';

const transformOptions = (item) => {
  if (!item?.id) return;

  let returnValues = {
    label: item?.student_name || 'Sem nome',
    key: item.id,
    value: item.id,
    cpf: item?.student_cpf,
  };

  return returnValues;
};

const renderOptions = (data, page) => {
  return {
    options: data.results.map(transformOptions),
    hasMore: data.next,
    additional: {
      page: page + 1,
    },
  };
};

export const loadStudent = async (search, loadedOptions, { page }) => {
  const { data } = await Student.list({ page, search });

  return renderOptions(data, page);
};
